import { useState } from 'react';
import { singletonHook } from 'react-singleton-hook';

const initMessage      = '';
let   currentMessage   = initMessage;
let   globalSetMessage = () => {};

export const useMessage = singletonHook( initMessage, () => {
    const [message, setMessage] = useState( initMessage );
    globalSetMessage = setMessage;
    currentMessage   = message;
    return message;
});

export const setMessage = message => globalSetMessage( message );
export const getMessage = () => currentMessage;
