import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import {Checkbox, FormControlLabel, FormGroup, Typography} from '@material-ui/core';
import axios from 'axios';
import conf from '../../../conf/conf';

const useStyles = makeStyles(theme => ({
    formControl : {
        width        : '100%',
        marginBottom : '1em',
        height       : '140px',
        overflow     : 'auto'
    },
    consultant : {
        maxHeight : '2em'
    },
    txt : {
        paddingTop : '10px',
        marginTop  : theme.spacing( 2 ),
        // color      : 'rgba( 0, 0, 0, 0.54 )',
        fontSize   : '1rem'
    }
}));
const Consultants = p => {
    const classes                             = useStyles();
    const [consultantList, setConsultantList] = useState([] );
    const [selected, setSelected]             = useState([] );

    const handleClick = ( e, uuid, data ) => {
        const selectedIndex = selected.indexOf( uuid );
        let   newSelected   = [];

        if ( selectedIndex === -1 ) {
            newSelected = newSelected.concat( selected, uuid );

            const newConsultant = p.consultants.slice();
            newConsultant.push( data );
            p.setConsultants( newConsultant );
        }

        else if ( selectedIndex === 0 ) {
            newSelected = newSelected.concat( selected.slice( 1 ) );

            let newConsultants = [];
            for ( let i = 0; i < newSelected.length; i++ ) {
                const newConsultant = p.consultants.filter( c => c.uuid === newSelected[i] );
                newConsultants.push( newConsultant[0] );
            }
            p.setConsultants( newConsultants );
        }

        else if ( selectedIndex === selected.length - 1 ) {
            newSelected = newSelected.concat( selected.slice( 0, -1 ) );

            let newConsultants = [];
            for ( let i = 0; i < newSelected.length; i++ ) {
                const newConsultant = p.consultants.filter( c => c.uuid === newSelected[i] );
                newConsultants.push( newConsultant[0] );
            }
            p.setConsultants( newConsultants );
        }

        else if ( selectedIndex > 0 ) {
            newSelected = newSelected.concat(
                selected.slice( 0, selectedIndex ),
                selected.slice( selectedIndex + 1 ),
            );

            let newConsultants = [];
            for ( let i = 0; i < newSelected.length; i++ ) {
                console.log(p.consultants.length)
                console.log(p.consultants)
                const newConsultant = p.consultants.filter( c => c.uuid === newSelected[i] );
                newConsultants.push( newConsultant[0] );
            }
            p.setConsultants( newConsultants );
        }

        setSelected( newSelected );
    };
    const isSelected = uuid => selected.indexOf( uuid ) !== -1;

    useEffect( () => {
        axios({
            method : 'get',
            url    : `${conf.getEnv().nodeServer}/herd/update/list/consultant`
        })
        .then( res => {
            if ( res.data.success )
                setConsultantList( res.data.data );
        });
    }, [] );
    return (
        <>
            <Typography className = {classes.txt} >Consultant</Typography>
            <div className = {classes.formControl} >
                <FormControl>
                    <FormGroup>
                        {consultantList.map( consultant => {
                            const isItemSelected = isSelected( consultant.uuid );
                            return (
                                <FormControlLabel
                                    key       = {consultant.uuid}
                                    className = {classes.consultant}
                                    control   = {
                                        <Checkbox
                                            checked = {isItemSelected}
                                            onClick = { e => handleClick(
                                                e,
                                                consultant.uuid,
                                                {
                                                    uuid : consultant.uuid,
                                                    name : consultant.name
                                                }
                                            )}
                                        />
                                    }
                                    label = {consultant.name}
                                />
                            )})}
                    </FormGroup>
                </FormControl>
            </div>
        </>
    );
}

export default Consultants;
