import * as React from 'react'
import Button from '@mui/material/Button'

const Buttons = p => {

    return (
        <>
            <Button
                sx       = {{ my: 0.5 }}
                variant  = 'outlined'
                size     = 'small'
                onClick  = { p.handleAllRight }
                disabled = { p.left.length === 0 } >
                ≫
            </Button>
            <Button
                sx       = {{ my: 0.5 }}
                variant  = 'outlined'
                size     = 'small'
                onClick  = { p.handleCheckedRight }
                disabled = { p.leftChecked.length === 0 } >
                &gt;
            </Button>
            <Button
                sx       = {{ my: 0.5 }}
                variant  = 'outlined'
                size     = 'small'
                onClick  = { p.handleCheckedLeft }
                disabled = { p.rightChecked.length === 0 } >
                &lt;
            </Button>
            <Button
                sx       = {{ my: 0.5 }}
                variant  = 'outlined'
                size     = 'small'
                onClick  = { p.handleAllLeft }
                disabled = { p.right.length === 0 } >
                ≪
            </Button>
        </>
    )
}

export default Buttons
