import React, {useEffect} from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemText from '@material-ui/core/ListItemText';
import {Paper} from '@material-ui/core';
import {useType, getType, setType} from '../../../../../singletons/reports/type/singletonType';

const Types = () => {
    useType();

    const handleListItemClick = ( e, index ) => {
        setType( index );
    };

    useEffect( () => {
        setType( 4 );
    }, [] )

    return (
        <Paper style = {{marginLeft : '1em', marginBottom : '1em'}} >
            <List
                disablePadding
                component = 'div'
                subheader = {
                    <ListSubheader
                        component = 'div' >
                        Reports
                    </ListSubheader>
                } >
                <ListItem
                    button
                    selected  = {getType() === 4}
                    onClick   = {e => handleListItemClick( e, 4 )} >
                    <ListItemText
                        primary = 'Summary Milk'
                    />
                </ListItem>
                <ListItem
                    button
                    selected  = {getType() === 5}
                    onClick   = {e => handleListItemClick( e, 5 )} >
                    <ListItemText
                        primary = 'Performance Milk'
                    />
                </ListItem>
            </List>
        </Paper>
    );
}

export default Types;
