import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import EnhancedTableToolbar from './EnhancedTableToolbar';
import EnhancedTableHead from './EnhancedTableHead';
import axios from 'axios';
import conf from '../../../../../conf/conf';
import {useHerds, getHerds, setHerds} from '../../../../../singletons/reports/herds/singletonHerds';
import {useConsultants, getConsultants} from '../../../../../singletons/reports/consultants/singletonConsultants';

const descendingComparator = ( a, b, orderBy ) => {
    if ( b[orderBy] < a[orderBy] )
        return -1;
    if ( b[orderBy] > a[orderBy] )
        return 1;
    return 0;
}
const getComparator = ( order, orderBy ) => {
    return order === 'desc'
        ? ( a, b ) => descendingComparator( a, b, orderBy )
        : ( a, b ) => -descendingComparator( a, b, orderBy );
}
const stableSort = ( array, comparator ) => {
    const stabilizedThis = array.map( ( el, index ) => [el, index] );

    stabilizedThis.sort( ( a, b ) => {
        const order = comparator( a[0], b[0] );

        if ( order !== 0 )
            return order;

        return a[1] - b[1];
    });

    return stabilizedThis.map( el => el[0] );
}
const useStyles = makeStyles(( theme) => ({
    root : {
        marginLeft  : theme.spacing( 2 ),
        marginRight : theme.spacing( 2 )
    },
    paper : {
        height       : '23.5em',
        overflow     : 'auto',
        marginBottom : theme.spacing( 2 )
    },
    visuallyHidden : {
        border   : 0,
        clip     : 'rect(0 0 0 0)',
        height   : 1,
        margin   : -1,
        overflow : 'hidden',
        padding  : 0,
        position : 'absolute',
        top      : 20,
        width    : 1
    }
}));

const Herds = () => {
    useHerds();
    useConsultants();
    const classes                           = useStyles();
    const [rows, setRows]                   = useState([] );
    const [order, setOrder]                 = useState('asc' );
    const [orderBy, setOrderBy]             = useState('herdName' );
    const page                              = 0;
    const rowsPerPage                       = rows.length;

    const handleRequestSort = ( e, property ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy( property );
    };
    const handleSelectAllClick = e => {
        if ( e.target.checked ) {
            setHerds( rows );
            return;
        }
        setHerds( [] );
    };
    const handleClick = data => {
        let   newSelected = [...getHerds()];
        let   isMatch     = false;
        const herds       = getHerds();

        for ( let i = 0; i < herds.length; i++ ) {
            if ( herds[i].herdId === data.herdId && herds[i].consultantId === data.consultantId ) {
                isMatch = true;
                newSelected.splice( i, 1 );
            }
        }
        if ( ! isMatch )
            newSelected.push( data );
        setHerds( newSelected );
    };
    const isRowSelected = row => {
        let   isSelected = false;
        const herds      = getHerds();

        herds.forEach( selected => {
            if ( row.herdId === selected.herdId && row.consultantId === selected.consultantId )
                isSelected = true;
        });
        return isSelected;
    };
    const emptyRows  = rowsPerPage - Math.min( rowsPerPage, rows.length - page * rowsPerPage );
    useEffect( () => {
        if ( getConsultants().length === 0 ) {
            setRows( [] );
            setHerds( [] );
            return;
        }
        const selectedConsultants = getConsultants();
        const selectedHerds       = getHerds();
        axios({
            method : 'put',
            url    : `${conf.getEnv().nodeServer}/report/breed/list/herds`,
            data   : {
                consultants : getConsultants()
            }
        })
        .then( res => {
            if ( res.data.success ) {
                setRows( res.data.data );
                // Filter the herds list.
                let updatedSelectedHerds = [];
                for ( let i = 0; i < selectedConsultants.length; i++ ) {
                    for ( let k = 0; k < selectedHerds.length; k++ ) {
                        if ( selectedConsultants[i].uuid === selectedHerds[k].consultantId
                            && selectedConsultants[i].region === selectedHerds[k].region
                        )
                            updatedSelectedHerds.push( selectedHerds[k] );
                    }
                }
                setHerds( updatedSelectedHerds );
            }
        });
    }, [getConsultants()] )
    return (
        <div
            className = {classes.root} >
            <Paper
                className = {classes.paper} >
                <EnhancedTableToolbar
                    numSelected = {getHerds().length}
                />
                <TableContainer>
                    <Table
                        size = 'small' >
                        <EnhancedTableHead
                            classes          = {classes}
                            numSelected      = {getHerds().length}
                            order            = {order}
                            orderBy          = {orderBy}
                            onSelectAllClick = {handleSelectAllClick}
                            onRequestSort    = {handleRequestSort}
                            rowCount         = {rows.length}
                        />
                        <TableBody>
                            {
                                stableSort( rows, getComparator( order, orderBy ) )
                                    .slice( page * rowsPerPage, page * rowsPerPage + rowsPerPage )
                                    .map( ( row, index ) => {
                                        const isSelected = isRowSelected( { herdId : row.herdId, consultantId : row.consultantId } );

                                        return (
                                            <TableRow
                                                hover
                                                onClick = {() => handleClick( row )}
                                                role         = 'checkbox'
                                                key          = {row.key}
                                                selected     = {isSelected} >
                                                <TableCell
                                                    padding = 'checkbox' >
                                                    <Checkbox
                                                        checked = {isSelected}
                                                        color   = 'primary'
                                                    />
                                                </TableCell>
                                                <TableCell
                                                    component = 'th'
                                                    id        = {index}
                                                    scope     = 'row'
                                                    padding   = 'none' >
                                                    {row.herdName}
                                                </TableCell>
                                                <TableCell>
                                                    {row.consultant}
                                                </TableCell>
                                                <TableCell>
                                                    {row.driver}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            {
                                emptyRows > 0 && (
                                    <TableRow
                                        style = {{height : (33 * emptyRows)}} >
                                        <TableCell
                                            colSpan = {6}
                                        />
                                    </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    );
}

export default Herds;
