import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Checkbox, ListItem, ListItemText, Paper} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import {useHouse, getHouse, setHouse} from '../../../../../singletons/reports/house/singletonHouse';

const useStyles = makeStyles(theme => ({
    root : {
        minWidth : 158
    },
    paper : {
        marginTop : theme.spacing( 2 ),
        marginBottom : theme.spacing( 2 )
    },
    drylot : {
        maxHeight : '2em'
    },
    freestall : {
        maxHeight     : '2em',
        paddingTop    : '9px',
        paddingBottom : '12px'
    },
    txt : {
        '& .MuiTypography-body2' : {
            fontSize : '0.75rem'
        },
        marginBottom : theme.spacing( 0.5 ),
    }
}));
const House = () => {
    useHouse();
    const classes = useStyles();

    const handleCheckAll = () => {
        if ( getHouse().length < 2 ) {
            let newHouse = [];
            newHouse.push( 'drylot' );
            newHouse.push( 'freestall' );
            setHouse( newHouse );
        }
        else
            setHouse( [] );
    };
    const handleCheckDrylot = () => {
        let newHouse = [...getHouse()];
        if ( newHouse.includes( 'drylot' ) )
            newHouse = newHouse.filter( e => e !== 'drylot' );
        else
            newHouse.push( 'drylot' );
        setHouse( newHouse );
    };
    const handleCheckFreestall = () => {
        let newHouse = [...getHouse()];
        if ( newHouse.includes( 'freestall' ) )
            newHouse = newHouse.filter( e => e !== 'freestall' );
        else
            newHouse.push( 'freestall' );
        setHouse( newHouse );
    };

    return (
        <div
            className = {classes.root} >
            <Paper className = {classes.paper} >
                <ListItem
                    dense
                    button
                    key = {0} >
                    <Checkbox
                        edge     = 'start'
                        checked  = {getHouse().length === 2 }
                        onChange = {handleCheckAll}
                    />
                    <ListItemText
                        primary   = 'Housing'
                        className = {classes.txt}
                    />
                </ListItem>
                <Divider />
                <ListItem key = {1} dense button onClick = {handleCheckDrylot} className = {classes.drylot} >
                    <Checkbox
                        edge    = 'start'
                        checked = {getHouse().includes( 'drylot' )}
                    />
                    <ListItemText primary = 'Drylot' />
                </ListItem>
                <ListItem key = {2} dense button onClick = {handleCheckFreestall} className = {classes.freestall} >
                    <Checkbox
                        edge    = 'start'
                        checked = {getHouse().includes( 'freestall' )}
                    />
                    <ListItemText primary = 'Freestall' />
                </ListItem>
            </Paper>
        </div>
    );
}

export default House;
