import React, {useEffect, useState} from 'react';
import Login from './login/Login';
import {TopBar} from './topBar/TopBar';
import TopNav from './topNav/TopNav';
import axios from 'axios';
import conf from './conf/conf';

const App = () => {
    const [environment, setEnvironment]   = useState( true );

    const handleEnvironment = environment => {
        if ( environment.indexOf( '100.80' ) >= 0 )
            return true;
        if ( environment.indexOf( '100.46' ) >= 0 )
            return true;
        if ( environment.indexOf( '100.41' ) >= 0 )
            return false;
    };

    useEffect( () => {
        axios({
            method : 'get',
            url    : `${conf.getEnv().nodeServer}/login/environment`
        })
            .then( res => {
                // console.log(res.data)
                if ( res.data.success )
                    setEnvironment( handleEnvironment( res.data.data ) );
            });
    }, [] )

    return (
        <>
            {
                environment ?
                    <Login /> :
                    <>
                        <TopBar />
                        <TopNav />
                    </>
            }
        </>
    );
}

export default App;
