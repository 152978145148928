import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import {Edit} from '@material-ui/icons';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Regions from './Regions';
import Consultants from './Consultants';
import Creamery from './Creamery';
import Divider from '@material-ui/core/Divider';
import axios from 'axios';
import conf from '../../../conf/conf';

const useStyles = makeStyles({
    root : {
        width : '100%'
    },
    container : {
        maxHeight : 724
    }
});
const Update = props => {
    const classes                       = useStyles();
    const [rows, setRows]               = useState( [] );
    const [open, setOpen]               = useState( false );
    const [disable, setDisable]         = useState( true );
    const [region, setRegion]           = useState( '' );
    const [creamery, setCreamery]       = useState( '' );
    const [consultants, setConsultants] = useState( [] );
    const [uuid, setUuid]               = useState( '' );
    const [herdName, setHerdName]       = useState( '' );

    const handleOpen = ( uuid, name ) => {
        setUuid( uuid );
        setHerdName( name );
        setOpen(true );
    };
    const handleClose = () => {
        setUuid( '' );
        setRegion( '' );
        setCreamery( '' );
        setConsultants( [] );
        setOpen(false );
    };
    const handleSubmit = () => {
        // console.log(region)
        // console.log(creamery)
        // console.log(consultants)
        // console.log(uuid)
        // console.log(herdName)
        axios({
            method : 'put',
            url    : `${conf.getEnv().nodeServer}/herd/update`,
            data   : {
                herdId      : uuid,
                herdName    : herdName,
                region      : region,
                creamery    : creamery,
                consultants : consultants
            }
        })
        .then( () => {
        });
        handleClose();
    };

    useEffect( () => {
        axios({
            method : 'get',
            url    : `${conf.getEnv().nodeServer}/herd/update/list`
        })
        .then( res => {
            if ( res.data.success ) {
                // console.log(res.data.data);
                const rows = res.data.data;
                setRows( rows );
                let cntBadge = 0;
                rows.forEach( row => {
                    if ( row.update )
                        cntBadge++;
                })
                props.setBadge( cntBadge );
            }
        });
    }, [rows] );
    useEffect( () => {
        setDisable( true );
            if ( region )
                if ( creamery )
                    if ( consultants.length > 0 )
                        setDisable( false );
    }, [
        region,
        creamery,
        consultants
    ]);

    return (
        <>
            <Paper
                className = {classes.root} >
                <TableContainer
                    className = {classes.container} >
                    <Table
                        stickyHeader
                        size = 'small' >
                        <TableHead>
                            <TableRow>
                                <TableCell style = {{minWidth : 170, backgroundColor : '#606060'}} >NAME</TableCell>
                                <TableCell style = {{minWidth : 170, backgroundColor : '#606060'}} >CREAMERY</TableCell>
                                <TableCell style = {{minWidth : 170, backgroundColor : '#606060'}} >REGION</TableCell>
                                <TableCell style = {{minWidth : 170, backgroundColor : '#606060'}} >CONSULTANT</TableCell>
                                <TableCell style = {{minWidth : 170, backgroundColor : '#606060'}} >UPDATE</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map( row => (
                                <TableRow
                                    key = {row.uuid} >
                                    <TableCell>{row.herdName}</TableCell>
                                    <TableCell>{row.creamery}</TableCell>
                                    <TableCell>{row.region}</TableCell>
                                    <TableCell>{row.consultants}</TableCell>
                                    <TableCell>
                                        {
                                            row.update ?
                                                <IconButton component = 'span' size = 'small' onClick = {() => handleOpen( row.uuid, row.herdName )} >
                                                    <Edit />
                                                </IconButton>
                                                :
                                                <></>
                                        }
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            <Dialog open = {open} onClose = {handleClose} fullWidth = {true} maxWidth = {'sm'} >
                <DialogTitle>Update Herd {herdName}</DialogTitle>
                <DialogContent>
                    <Regions setRegion = {setRegion} region = {region} />
                    <Creamery setCreamery = {setCreamery} creamery = {creamery} />
                    <Consultants setConsultants = {setConsultants} consultants = {consultants} />
                </DialogContent>
                <Divider variant = 'middle' />
                <DialogActions>
                    <Button onClick = {handleClose} >
                        Cancel
                    </Button>
                    <Button onClick = {handleSubmit} disabled = {disable} >
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default Update;
