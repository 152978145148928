import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import React from 'react';

const headCells = [
    { id : 'fullName',  numeric: false, disablePadding : true,  label : 'FULL NAME' },
    { id : 'lastName',  numeric: false, disablePadding : false, label : 'LAST NAME' },
    { id : 'region',    numeric: false, disablePadding : false, label : 'REGION' }
];

const EnhancedTableHead = p => {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = p;

    const createSortHandler = property => event => {
        onRequestSort( event, property );
    };
    return (
        <TableHead>
            <TableRow>
                <TableCell
                    padding = 'checkbox' >
                    <Checkbox
                        indeterminate = {numSelected > 0 && numSelected < rowCount}
                        checked       = {rowCount > 0 && numSelected === rowCount}
                        onChange      = {onSelectAllClick}
                    />
                </TableCell>
                {
                    headCells.map( headCell => (
                        <TableCell
                            key           = {headCell.id}
                            align         = {headCell.numeric ? 'right' : 'left'}
                            padding       = {headCell.disablePadding ? 'none' : 'default'}
                            sortDirection = {orderBy === headCell.id ? order : false} >
                            <TableSortLabel
                                active    = {orderBy === headCell.id}
                                direction = {orderBy === headCell.id ? order : 'asc'}
                                onClick   = {createSortHandler( headCell.id )} >
                                {headCell.label}
                                {
                                    orderBy === headCell.id ? (
                                        <span
                                            className = {classes.visuallyHidden} >
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                    ) :
                                    null
                                }
                            </TableSortLabel>
                        </TableCell>
                    ))}
            </TableRow>
        </TableHead>
    );
}

export default EnhancedTableHead;
