import React from 'react';
import S_Summary from './views/summaryView/summary/Summary';
import S_SummaryMilk from './views/summaryView/summaryMilk/SummaryMilk';
import S_PerformanceMilk from './views/summaryView/performanceMilk/PerformanceMilk';
import S_ReproductionReport from './views/summaryView/reproductionReport/ReproductionReport';
import B_SummaryMilk from './views/breed/summaryMilk/SummaryMilk';
import B_PerformanceMilk from './views/breed/performanceMilk/PerformanceMilk';
import {useType, getType} from '../../../singletons/reports/type/singletonType';

const Viewer = p => {
    useType();

    return (
        <>
            { getType() === 0 && ( <S_Summary collapse = {p.collapse} /> ) }
            { getType() === 1 && ( <S_SummaryMilk collapse = {p.collapse} /> ) }
            { getType() === 2 && ( <S_PerformanceMilk collapse = {p.collapse} /> ) }
            { getType() === 3 && ( <S_ReproductionReport collapse = {p.collapse} /> ) }
            { getType() === 4 && ( <B_SummaryMilk collapse = {p.collapse} /> ) }
            { getType() === 5 && ( <B_PerformanceMilk collapse = {p.collapse} /> ) }
        </>
    )
}

export default Viewer;
