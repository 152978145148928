import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

// const darkTheme = createMuiTheme({
//     palette : {
//         type    : 'dark',
//         primary : {
//             light        : '#337066',
//             main         : '#004d40',
//             dark         : '#00352c',
//             contrastText : '#FFF'
//         },
//         secondary : {
//             light        : '#ff7961',
//             main         : '#f44336',
//             dark         : '#ba000d',
//             contrastText : '#FFF'
//         }
//     }
// });

// ReactDOM.render(
//         <App />,
//     document.getElementById('root' )
// );

const darkTheme = createTheme({
    palette : {
        type      : 'dark',
        primary   : { main : '#FFF' },
        // primary   : { main : '#28546C' },
        secondary : { main : '#FFF', contrastText : '#FFF' } // AA7439 216A12
    }
});

ReactDOM.render(
    <ThemeProvider
        theme = {darkTheme} >
        <CssBaseline />
        <App />
    </ThemeProvider>,
    document.getElementById('root' )
);