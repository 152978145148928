import Toolbar from '@material-ui/core/Toolbar';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import {lighten, makeStyles} from '@material-ui/core/styles';

const useToolbarStyles = makeStyles(theme => ({
    root : {
        paddingLeft  : theme.spacing( 2 ),
        paddingRight : theme.spacing( 1 )
    },
    highlight :
        theme.palette.type === 'light'
            ? {
                // color           : theme.palette.primary,
                // backgroundColor : lighten( theme.palette.primary.light, 0.85 )
            }
            : {
                // color           : theme.palette.text.primary,
                // backgroundColor : theme.palette.primary.dark
            },
    title : {
        flex : '1 1 100%'
    },
    enhancedTableToolbar : {
        minHeight : '3em'
    }
}));

const EnhancedTableToolbar = p => {
    const classes         = useToolbarStyles();
    const { numSelected } = p;

    return (
        <Toolbar
            className = { clsx( classes.root, classes.enhancedTableToolbar, { [classes.highlight] : numSelected > 0 } ) } >
            {
                numSelected > 0 ? (
                <Typography
                    className = {classes.title}
                    color     = 'inherit'
                    variant   = 'subtitle1'
                    component = 'div' >
                    { numSelected === 1 ? `${numSelected} Consultant Selected` : `${numSelected} Consultants Selected` }
                </Typography>
            ) : (
                <Typography
                    className = {classes.title}
                    variant   = 'h6'
                    component = 'div' >
                    Consultants
                </Typography>
            )}
        </Toolbar>
    );
};

export default EnhancedTableToolbar;
