import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowDown from '@material-ui/icons/ArrowDownward';
import ArrowUp from '@material-ui/icons/ArrowUpward';
import {useMessage, getMessage, setMessage} from '../../../../singletons/reports/message/singletonMessage';

const useStyles = makeStyles((theme) => ({
    root : {
        marginLeft   : theme.spacing( 2 ),
        marginRight  : theme.spacing( 2 ),
        marginBottom : theme.spacing( 2 )
    },
    button : {
        marginRight : theme.spacing( 2 )
    }
}));

const Collapse = p => {
    useMessage();
    const classes = useStyles();
    let   message = getMessage();
    setTimeout( () => {
        setMessage( '' );
    },3500 );
    return (
        <div className = {classes.root} >
            <AppBar
                elevation = {0}
                position  = 'static' >
                <Toolbar variant = 'dense' >
                    <IconButton
                        edge      = 'start'
                        className = {classes.button}
                        color     = 'inherit'
                        onClick   = {p.handleCollapse} >
                        {
                            p.collapse ? <ArrowDown /> : <ArrowUp />
                        }
                    </IconButton>
                    <Typography
                        variant = 'h6' >
                        Report Parameters
                    </Typography>
                    <Typography
                        variant = 'h6'
                        style   = {{marginLeft : '25px'}} >
                        {message}
                    </Typography>
                </Toolbar>
            </AppBar>
        </div>
    );
}

export default Collapse;
