import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Checkbox, ListItem, ListItemText, Paper} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import axios from 'axios';
import conf from '../../../../../conf/conf';
import {useBreeds, getBreeds, setBreeds, useBreedsCheckedAll, getBreedsCheckedAll, setBreedsCheckedAll} from '../../../../../singletons/reports/breeds/singletonBreeds';
import {useHerds, getHerds} from '../../../../../singletons/reports/herds/singletonHerds';
import {
    useDateB,
    getDateB,
    useDateE,
    getDateE,
    getDateStringB, getDateStringE
} from '../../../../../singletons/reports/dates/singletonDates';

const useStyles = makeStyles(theme => ({
    root : {
        minWidth : 158
    },
    breed : {
        maxHeight : '2em'
    },
    txt : {
        '& .MuiTypography-body2' : {
            fontSize : '0.75rem'
        },
        marginBottom : theme.spacing( 0.5 )
    }
}));
const Breeds = () => {
    useBreedsCheckedAll();
    useBreeds();
    useHerds();
    useDateB();
    useDateE();
    const classes                       = useStyles();
    const [rows, setRows]               = useState([] );

    const handleChangeAll = e => {
        if ( e.target.checked ) {
            let newBreeds = [...rows];
            setBreeds( newBreeds );
            setBreedsCheckedAll( false );
            if ( newBreeds.length > 0 )
                setBreedsCheckedAll( true );
        }
        else {
            setBreeds( [] );
            setBreedsCheckedAll( false );
        }
    };
    const handleChangeBreed = e => {
        const currentIndex = getBreeds().indexOf( e.target.name );
        let   newBreeds    = [...getBreeds()];
        if ( currentIndex === -1 )
            newBreeds.push( e.target.name );
        else
            newBreeds.splice( currentIndex, 1 );
        setBreeds( newBreeds );
        setBreedsCheckedAll( false );
        if ( newBreeds.length === rows.length )
            setBreedsCheckedAll( true );
    };
    useEffect( () => {
        if ( getHerds().length === 0 ) {
            setRows( [] );
            setBreeds( [] );
            return;
        }
        axios({
            method : 'put',
            url    : `${conf.getEnv().nodeServer}/report/breed/list/breeds`,
            data   : {
                beginDate : getDateStringB(),
                endDate   : getDateStringE(),
                herds     : getHerds()
            }
        })
        .then( res => {
            if ( res.data.success )
                setRows( res.data.data );
        });
    }, [getDateStringB(), getDateStringE(), getHerds()] )

    return (
        <div
            className = {classes.root} >
            <Paper >
                <ListItem
                    dense
                    button
                    key = {0} >
                    <Checkbox
                        edge     = 'start'
                        checked  = {getBreedsCheckedAll()}
                        onChange = {handleChangeAll}
                    />
                    <ListItemText
                        primary   = 'Breeds'
                        className = {classes.txt}
                    />
                </ListItem>
                <Divider />
                {
                    rows.map( ( row, index ) => {
                        return (
                            <ListItem
                                dense
                                button
                                key       = {index}
                                className = {classes.breed} >
                                <Checkbox
                                    edge     = 'start'
                                    name     = {row}
                                    checked  = {getBreeds().indexOf( row ) !== -1}
                                    onChange = {handleChangeBreed}
                                />
                                <ListItemText
                                    primary = {row}
                                />
                            </ListItem>
                        );
                    })
                }
            </Paper>
        </div>
    );
}

export default Breeds;
