import { useState } from 'react';
import { singletonHook } from 'react-singleton-hook';

const initRegions      = [];
let   currentRegions   = initRegions;
let   globalSetRegions = () => {};

export const useRegions = singletonHook( initRegions, () => {
    const [regions, setRegions] = useState( initRegions );
    globalSetRegions = setRegions;
    currentRegions   = regions;
    return regions;
});

export const setRegions = r => globalSetRegions( r );
export const getRegions = () => currentRegions;

// ################################################################################################

const initRegionsChecked      = [];
let   currentRegionsChecked   = initRegionsChecked;
let   globalSetRegionsChecked = () => {};

export const useRegionsChecked = singletonHook( initRegionsChecked, () => {
    const [regionsChecked, setRegionsChecked] = useState( initRegionsChecked );
    globalSetRegionsChecked = setRegionsChecked;
    currentRegionsChecked   = regionsChecked;
    return regionsChecked;
});

export const setRegionsChecked = rc => globalSetRegionsChecked( rc );
export const getRegionsChecked = () => currentRegionsChecked;

// ################################################################################################

const initRegionsCheckedAll      = false;
let   currentRegionsCheckedAll   = initRegionsCheckedAll;
let   globalSetRegionsCheckedAll = () => {};

export const useRegionsCheckedAll = singletonHook( initRegionsCheckedAll, () => {
    const [regionsCheckedAll, setRegionsCheckedAll] = useState( initRegionsCheckedAll );
    globalSetRegionsCheckedAll = setRegionsCheckedAll;
    currentRegionsCheckedAll   = regionsCheckedAll;
    return regionsCheckedAll;
});

export const setRegionsCheckedAll = rca => globalSetRegionsCheckedAll( rca );
export const getRegionsCheckedAll = () => currentRegionsCheckedAll;
