import * as React from 'react'
import { TableRow, TableBody, TableCell, Table, TableContainer, Paper, Checkbox } from '@mui/material'

const ListHerdAssigned = p => {

    return (
        <Paper
            sx = {{ width: 400, height: 440, overflow: 'auto' }} >
            <TableContainer>
                <Table
                    size = 'small' >
                    <TableBody>
                        { p.left.map( ( row, index ) => {
                            return (
                                <TableRow
                                    hover
                                    role    = 'checkbox'
                                    key     = {index}
                                    onClick = {() => p.handleToggle( row.uid )} >
                                    <TableCell>
                                        <Checkbox
                                            checked = {p.checked.indexOf( row.uid ) !== -1}
                                        />
                                        { row.infoPacket.herdName }
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    )
}

export default ListHerdAssigned
