import React, {useState} from 'react';
import Collapse from './collapse/Collapse';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Summary from './Summary';
import Breed from './Breed';
import {useType, getType, setType} from '../../../singletons/reports/type/singletonType';

const Category = p => {
    useType();

    const [value, setValue] = useState(0 );

    const handleChange = ( event, newValue ) => {
        setValue( newValue );
    };

    return (
        <>
            {
                p.collapse ? (
                    <Collapse
                        collapse       = {p.collapse}
                        handleCollapse = {p.handleCollapse}
                    />
                    ) : (
                    <>
                        <Collapse
                            collapse       = {p.collapse}
                            handleCollapse = {p.handleCollapse}
                        />
                        <div style = {{display : 'flex', 'flexDirection' : 'row'}} >
                            <Tabs
                                orientation    = 'vertical'
                                variant        = 'scrollable'
                                value          = {value}
                                onChange       = {handleChange}
                                style          = {{minWidth : '12em', maxWidth : '12em', marginRight:'1em'}} >
                                <Tab label = 'Summary' />
                                <Tab label = 'Breed' />
                                <Tab label = 'Consultant' />
                            </Tabs>
                            { value === 0 &&
                                <Summary
                                    selectedType    = {getType}
                                    setSelectedType = {setType}
                                    handleCollapse  = {p.handleCollapse}
                                    setShowViewer   = {p.setShowViewer}
                                />
                            }
                            { value === 1 &&
                                <Breed
                                    selectedType    = {getType}
                                    setSelectedType = {setType}
                                    handleCollapse  = {p.handleCollapse}
                                    setShowViewer   = {p.setShowViewer}
                                />
                            }
                            {
                                value === 2 && '<Consultant />'
                            }
                        </div>
                    </>
                )
            }
        </>
    );
}

export default Category;
