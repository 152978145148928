import React from 'react';
import './SummaryMilkCss.css'

const B_SummaryMilk = p => {

    window
        .jQuery( '#b_summary_milk' )
        .telerik_ReportViewer({
            reportServer : {
                url      : 'https://rmtest.standardnutrition.com:83/',
                username : 'rsmith',
                password : 'Mercedes@1'
            },
            reportSource : {
                report : 'Breed/SummaryMilk'
            },
            documentMapVisible : false,
            scale              : 1.0,
            scaleMode          : 'FIT_PAGE_WIDTH',
            viewMode           : 'INTERACTIVE',
            printMode          : 'SPECIFIC',
            sendEmail          : { enabled : true },
        });

    return (
        <>
            {
                p.collapse ?
                    (
                        <div style = {{marginTop : '8em'}}
                             id    = 'b_summary_milk' >
                        </div>
                    ) :
                    (
                        <div style = {{display : 'none'}}
                             id    = 'b_summary_milk' >
                        </div>
                    )
            }
        </>
    )
}

export default B_SummaryMilk;
