import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Checkbox, ListItem, ListItemText, Paper} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import {useFreq, getFreq, setFreq} from '../../../../../singletons/reports/freq/singletonFreq';

const useStyles = makeStyles(theme => ({
    root : {
        minWidth : 158
    },
    paper : {
        marginBottom : theme.spacing( 2 ),
    },
    two : {
        maxHeight : '2em'
    },
    three : {
        maxHeight     : '2em',
        paddingTop    : '9px',
        paddingBottom : '12px'
    },
    txt : {
        '& .MuiTypography-body2' : {
            fontSize : '0.75rem'
        },
        marginBottom : theme.spacing( 0.5 ),
    }
}));
const Freq = () => {
    useFreq();
    const classes = useStyles();

    const handleCheckAll = () => {
        if ( getFreq().length < 2 ) {
            let newFreq = [];
            newFreq.push( 'two' );
            newFreq.push( 'three' );
            setFreq( newFreq );
        }
        else
            setFreq( [] );
    };
    const handleCheckTwo = () => {
        let newFreq = [...getFreq()];
        if ( newFreq.includes( 'two' ) )
            newFreq = newFreq.filter( e => e !== 'two' );
        else
            newFreq.push( 'two' );
        setFreq( newFreq );
    };
    const handleCheckThree = () => {
        let newFreq = [...getFreq()];
        if ( newFreq.includes( 'three' ) )
            newFreq = newFreq.filter( e => e !== 'three' );
        else
            newFreq.push( 'three' );
        setFreq( newFreq );
    };

    return (
        <div
            className = {classes.root} >
            <Paper className = {classes.paper} >
                <ListItem
                    dense
                    button
                    key = {0} >
                    <Checkbox
                        edge     = 'start'
                        checked  = {getFreq().length === 2 }
                        onChange = {handleCheckAll}
                    />
                    <ListItemText
                        primary   = 'Frequency'
                        className = {classes.txt}
                    />
                </ListItem>
                <Divider />
                <ListItem key = {1} dense button onClick = {handleCheckTwo} className = {classes.two} >
                    <Checkbox
                        edge    = 'start'
                        checked = {getFreq().includes( 'two' )}
                    />
                    <ListItemText primary = 'Two' />
                </ListItem>
                <ListItem key = {2} dense button onClick = {handleCheckThree} className = {classes.three} >
                    <Checkbox
                        edge    = 'start'
                        checked = {getFreq().includes( 'three' )}
                    />
                    <ListItemText primary = 'Three' />
                </ListItem>
            </Paper>
        </div>
    );
}

export default Freq;
