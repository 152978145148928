import React, {useState, useEffect, useRef} from 'react';
import {Input, InputAdornment, InputLabel, Paper} from '@material-ui/core';
import axios from 'axios';
import conf from '../conf/conf';
import Box from "@material-ui/core/Box";

import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import {Visibility, VisibilityOff} from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import Link from '@mui/material/Link';
import logo from '../logo/logo.png';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from "@material-ui/core/Button";
import {TopBar} from "../topBar/TopBar";
import TopNav from "../topNav/TopNav";
import {Alert} from "@material-ui/lab";
const CryptoJS = require('crypto-js');
const Cookies = require('js-cookie');

let environmentUrl = conf.getEnv().environment === 'production' ? 'https://rm.standardnutrition.com/#/Registration/SignUp' : 'https://rmtest.standardnutrition.com/#/Registration/SignUp'

const Login = () => {

    const [message, setMessage]                 = useState('');
    const [severity, setSeverity]               = useState('');
    const [email, setEmail]                     = useState('');
    const [password, setPassword]               = useState('');
    const [showPassword, setShowPassword]       = useState(false);
    const [isLoggedIn, setIsLoggedIn]           = useState(false);
    const [logout, setLogout]                   = useState(false);
    const pageLoad = useRef(true);


    const checkLoggedIn = () => {
        let authToken = Cookies.get('authToken') || null;
        if (authToken !== null) {
            axios({
                method : 'put',
                url    : `${conf.getEnv().nodeServer}/login/token`,
                data   : {
                    AuthToken  : authToken
                }
            }).then((response) => {
                let result = response.data;
                if (result.error == false) {
                    if (result.isAuthenticated === true) {
                        setIsLoggedIn(true);
                    }
                }
            })
        }
    }

    useEffect (() => {
        if (pageLoad.current) {
            checkLoggedIn()
            pageLoad.current = false
        }
    }, [])
    useEffect (() => {
        if (pageLoad.current) {
            pageLoad.current = false
            return
        }
        if (logout === true) {
            setIsLoggedIn(false);
            Cookies.set('authToken', null)
            setLogout(false);
        }
    }, [logout])

    const handleEmail = e => {
        setEmail( e.target.value );
    };
    const handlePassword = e => {
        setPassword( e.target.value );
    };
    const handleShowPassword = () => {
        setShowPassword( !showPassword );
    };
    const handleMouseDownPassword = e => {
        e.preventDefault();
    };
    const handleLogin = () => {
        if ( email === '' || password === '' ) {
            setSeverity( 'error' );
            setMessage( 'PLEASE ENTER EMAIL AND PASSWORD' );
            setTimeout( () => {
                setMessage( '' );
            }, 1300 );
            return;
        } else {
            let hashPassword = CryptoJS.SHA256(password).toString();
            const authToken = CryptoJS.SHA256(`${{email: email, password: hashPassword}}`).toString();
            
            Cookies.set('authToken', authToken, { expires: 1 });
            let payLoad = {
                Email      : email,
                Password   : hashPassword,
                AuthToken  : authToken
            };

            axios({
                method : 'put',
                url    : `${conf.getEnv().nodeServer}/login/auth`,
                data   : payLoad
            }).then((response) => {
                let result = response.data;
                if (result.error == false) {
                    
                    if (result.isAuthenticated === true) {
                        setSeverity( 'success' );
                        setMessage( 'LOG IN SUCCESSFUL' );
                        setTimeout( () => {
                            setMessage( '' );
                            setIsLoggedIn( true );
                        }, 1300 );
                    } else {
                        setSeverity('error');
                        setMessage(result.message);
                        setTimeout(() => {
                            setMessage('');
                        }, 1300);
                    }
                } else {
                    setSeverity('error');
                    setMessage(result.message);
                    setTimeout(() => {
                        setMessage('');
                    }, 1300);
                }
            })
        }
    };

    return (
        <>
            {
                isLoggedIn ?
                    <>
                        <TopBar setLogout={setLogout} />
                        <TopNav />
                    </> :
                    <Box
                        noValidate
                        display        = 'flex'
                        justifyContent = 'center'
                        alignItems     = 'center'
                        minHeight      = '80vh'
                        component      = 'form'
                        autoComplete   = 'off' >
                        <Paper
                            style = {{padding:'2em', minWidth:'30em'}} >
                            <AppBar
                                elevation = {0}
                                position  = 'static'
                                style     = {{background:'#000'}} >
                                <Toolbar>
                                    <img
                                        src = {logo}
                                        alt = 'logo'
                                    />
                                    <Typography
                                        variant = 'h6'
                                        style   = {{paddingLeft:'1em', color:'#FFF'}} >
                                        RM ULTIMATE
                                    </Typography>
                                </Toolbar>
                            </AppBar>
                            <div style = {{marginTop:'2em'}} >
                                <FormControl
                                    fullWidth
                                    variant = 'standard' >
                                    <InputLabel>Email</InputLabel>
                                    <Input
                                        autoFocus
                                        type         = 'text'
                                        value        = {email}
                                        onChange     = {handleEmail}
                                        endAdornment = {
                                            <MailOutlineIcon style = {{marginLeft:'.8em', marginRight:'.5em'}} />
                                        }
                                    />
                                </FormControl>
                            </div>
                            <div style = {{marginTop:'2em'}} >
                                <FormControl
                                    fullWidth
                                    variant = 'standard' >
                                    <InputLabel>Password</InputLabel>
                                    <Input
                                        type         = {showPassword ? 'text' : 'password'}
                                        value        = {password}
                                        onChange     = {handlePassword}
                                        endAdornment = {
                                            <InputAdornment position = 'end' >
                                                <IconButton
                                                    onClick     = {handleShowPassword}
                                                    onMouseDown = {handleMouseDownPassword} >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </div>
                            <div style = {{marginTop:'2em'}} >
                                <Button fullWidth variant = 'outlined' onClick = {handleLogin} >Log In</Button>
                            </div>
                            <div style = {{marginTop: '1em'}}> 
                                Don't have an account yet? <Link href={environmentUrl} color="inherit">Sign Up</Link>
                            </div>
                            {
                                !!message &&
                                <div style = {{marginTop:'1em'}} >
                                    <Alert severity = {severity} variant = 'outlined' >{message}</Alert>
                                </div>
                            }
                        </Paper>
                    </Box>
            }
        </>
    );
}

export default Login;
