import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Reports from './reports/Reports';
import Herds from './herds/Herds';
import Tools from './tools/Tools';
import About from './about/About';
import Box from '@material-ui/core/Box';
// import Users from './users/Users';

const useStyles = makeStyles(theme => ({
    tabs : {
        '& .MuiTabs-indicator' : {
            minHeight : '0.1em'
        }
    }
}));
const TopNav = () => {
    const classes           = useStyles();
    const [value, setValue] = useState(0 );

    const handleChange = ( event, newValue ) => {
        setValue( newValue );
    };

    return (
        <>
            <Box style = {{marginTop : '1em', marginBottom : '1em', marginLeft : '1em', marginRight : '1em'}} >
                <Tabs
                    value          = {value}
                    indicatorColor = 'primary'
                    textColor      = 'primary'
                    onChange       = {handleChange}
                    className      = {classes.tabs} >
                    <Tab
                        label = 'Herds'
                    />
                    {/*<Tab*/}
                    {/*    label = 'Users'*/}
                    {/*/>*/}
                    <Tab
                        label = 'Reports'
                    />
                    <Tab
                        label = 'Tools'
                    />
                    <Tab
                        label = 'About'
                    />
                </Tabs>
            </Box >
            {
                value === 0 && <Herds />
            }
            {/*{*/}
            {/*    value === 1 && <Users />*/}
            {/*}*/}
            {
                value === 1 && <Reports />
            }
            {
                value === 2 && <Tools />
            }
            {
                value === 3 && <About />
            }
        </>
    );
}

export default TopNav;
