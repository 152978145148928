import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles(theme => ({
    formControl : {
        width : '100%'
    }
}));

const Frequency = props => {

    const classes      = useStyles();
    const handleChange = e => {
        props.setFreq( e.target.value );
    };

    return (
        <FormControl required size = 'small' className = {classes.formControl} >
            <InputLabel id = 'freq-select-label' >Frequency</InputLabel>
            <Select
                labelId  = 'freq-select-label'
                id       = 'freq-select'
                value    = {props.freq}
                onChange = {handleChange} >
                <MenuItem value = {'Two'} >Two</MenuItem>
                <MenuItem value = {'Three'} >Three</MenuItem>
            </Select>
        </FormControl>
    );
}

export default Frequency;
