import {useEffect, useState} from 'react';
import Categpry from './category/Category';
import Viewer from './viewer/Viewer';
import {useType, setType} from '../../singletons/reports/type/singletonType';

const Reports = () => {
    useType();


    const [collapse, setCollapse]               = useState( false );
    const [showViewer, setShowViewer]           = useState( false );

    const handleCollapse = () => {
        setCollapse( ! collapse );
        if ( showViewer ) {
            setTimeout( () => {
                if ( window.jQuery( '#s_summary' ).data( 'telerik_ReportViewer' ) )
                    window.jQuery( '#s_summary' ).data( 'telerik_ReportViewer' ).refreshReport();
                if ( window.jQuery( '#s_summary_milk' ).data( 'telerik_ReportViewer' ) )
                    window.jQuery( '#s_summary_milk' ).data( 'telerik_ReportViewer' ).refreshReport();
                if ( window.jQuery( '#s_performance_milk' ).data( 'telerik_ReportViewer' ) )
                    window.jQuery( '#s_performance_milk' ).data( 'telerik_ReportViewer' ).refreshReport();
                if ( window.jQuery( '#s_reproduction_report' ).data( 'telerik_ReportViewer' ) )
                    window.jQuery( '#s_reproduction_report' ).data( 'telerik_ReportViewer' ).refreshReport();

                if ( window.jQuery( '#b_performance_milk' ).data( 'telerik_ReportViewer' ) )
                    window.jQuery( '#b_performance_milk' ).data( 'telerik_ReportViewer' ).refreshReport();
                if ( window.jQuery( '#b_summary_milk' ).data( 'telerik_ReportViewer' ) )
                    window.jQuery( '#b_summary_milk' ).data( 'telerik_ReportViewer' ).refreshReport();
            }, 400 );
        }
    };
    useEffect( () => {

        setType( 0 );
    }, [] )

    return (
        <>
            <Categpry
                collapse        = {collapse}
                handleCollapse  = {handleCollapse}
                setShowViewer   = {setShowViewer}
            />
            {
                showViewer &&
                <Viewer
                    collapse     = {collapse}
                />
            }
        </>
    )
}

export default Reports;
