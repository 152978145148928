import { useState } from 'react';
import { singletonHook } from 'react-singleton-hook';

const initHouse      = '';
let   currentHouse   = initHouse;
let   globalSetHouse = () => {};

export const useHouse = singletonHook( initHouse, () => {
    const [house, setHouse] = useState( initHouse );
    globalSetHouse = setHouse;
    currentHouse   = house;
    return house;
});

export const setHouse = h => globalSetHouse( h );
export const getHouse = () => currentHouse;
