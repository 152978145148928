import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    formControl : {
        width : '100%'
    }
}));

const Creamery = props => {

    const classes      = useStyles();
    const handleChange = e => {
        props.setCreamery( e.target.value );
    };
    return (
        <FormControl size = 'small' className = {classes.formControl} >
            <InputLabel id = 'creamery-label' >Creamery</InputLabel>
            <Select
                labelId  = 'creamery-select-label'
                id       = 'creamery-select'
                value    = {props.creamery}
                onChange = {handleChange} >
                <MenuItem value = '{ "name" : "None", "url" : "" }' >None</MenuItem>
                <MenuItem value = '{ "name" : "AgSource Laboratories", "url" : "https://comlab.myagsource.com/index.php/auth/login" }' >AgSource Laboratories</MenuItem>
                <MenuItem value = '{ "name" : "American Milk Producers", "url" : "https://www.ampi.com" }' >American Milk Producers</MenuItem>
                <MenuItem value = '{ "name" : "BelGioioso", "url" : "https://patron.belgioioso.com/Login?returnurl=%2f" }' >BelGioioso</MenuItem>
                <MenuItem value = '{ "name" : "Dairy Farmers of America", "url" : "https://www.dfamilk.com" }' >Dairy Farmers of America</MenuItem>
                <MenuItem value = '{ "name" : "Saputo",                   "url" : "https://saputo.com/en" }' >Saputo</MenuItem>
            </Select>
        </FormControl>
    );
};

export default Creamery;
