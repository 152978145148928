import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Update from './revenueMetric/Update';
import Update2 from './herdAssignment/Update';

const TabPanel = props => {
    const { children, value, index } = props;

    return (
        <div
            hidden          = {value !== index}
            id              = {`vertical-tabpanel-${index}`}
            aria-labelledby = {`vertical-tab-${index}`} >
            {value === index && (
                <Box style = {{marginLeft : '2em', marginRight : '2em'}} >
                    {children}
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children : PropTypes.node,
    index    : PropTypes.any.isRequired,
    value    : PropTypes.any.isRequired
};
const useStyles = makeStyles(theme => ({
    root : {
        flexGrow    : 1,
        display     : 'flex',
        marginLeft  : '1em',
        marginRight : '1em'
    },
    tab : {
        borderRight : `2px solid ${theme.palette.divider}`,
        minWidth    : '12em'
    },
    tabs : {
        minWidth : '12em'
    }
}));
const Tools = () => {
    const classes                       = useStyles();
    const [value, setValue]             = useState(0 );

    const handleChange = ( event, newValue ) => {
        setValue( newValue );
    };

    return (
        <div
            className = {classes.root} >
            <Tabs
                orientation = 'vertical'
                variant     = 'scrollable'
                value       = {value}
                onChange    = {handleChange}
                className   = {classes.tabs} >
                <Tab label = 'Revenue Metric' value = {0} />
                <Tab label = 'Herd Assignment' value = {1} />
            </Tabs>
            <TabPanel
                value = {value}
                index = {0} >
                <Update />
            </TabPanel>
            <TabPanel
                value = {value}
                index = {1} >
                <Update2 />
            </TabPanel>
        </div>
    );
}

export default Tools;
