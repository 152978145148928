import React, {useEffect, useState} from 'react';
import {Paper, TableCell} from '@material-ui/core';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import axios from 'axios';
import conf from '../../conf/conf';

const About = () => {
    const version                         = conf.getEnv().version;
    const [environment, setEnvironment]   = useState('Working ...' );
    const [herdCnt, setHerdCnt]           = useState('Working ...' );
    const [evalCnt, setEvalCnt]           = useState('Working ...' );

    useEffect( () => {
        axios({
            method : 'get',
            url    : `${conf.getEnv().nodeServer}/about/list`
        })
            .then( res => {
                // console.log(res.data)
                if ( res.data.success ) {
                    setEnvironment( res.data.data.environment );
                    setHerdCnt( res.data.data.herdCnt );
                    setEvalCnt( res.data.data.evalCnt );
                }
            });
    }, [] )

    return (
        <TableContainer component = {Paper} style = {{maxWidth : '45em', marginLeft : '1em'}} >
            <Table style = {{maxWidth : '50em'}} >
                <TableBody>
                    <TableRow><TableCell>Name</TableCell><TableCell>RM Ultimate</TableCell></TableRow>
                    <TableRow><TableCell>Version</TableCell><TableCell>{version}</TableCell></TableRow>
                    <TableRow><TableCell>Environment</TableCell><TableCell>{environment}</TableCell></TableRow>
                    <TableRow><TableCell>Company</TableCell><TableCell>Standard Nutrition</TableCell></TableRow>
                    <TableRow><TableCell>Director of Nutrition</TableCell><TableCell>Dan Illg Ph.D.</TableCell></TableRow>
                    <TableRow><TableCell>Nutritionist</TableCell><TableCell>Chad Jenkins M.Sc.</TableCell></TableRow>
                    <TableRow><TableCell>Author</TableCell><TableCell>Ray Smith</TableCell></TableRow>
                    <TableRow><TableCell>Total Herds</TableCell><TableCell>{herdCnt}</TableCell></TableRow>
                    <TableRow><TableCell>Total Evaluations</TableCell><TableCell>{evalCnt}</TableCell></TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default About;
