import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Regions from './Regions';
import Consultants from './Consultants';
import SoftwareDriver from './SoftwareDriver';
import Frequency from './Frequency';
import Housing from './Housing';
import Action from './Action';
import HerdName from './HerdName';
import DisplayName from './DisplayName';
import Creamery from './Creamery';
import Goals from './Goals';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
    root : {
        '& .MuiTextField-root' : {
            width    : '100%',
            minWidth : '18em'
        }
    }
}));
const Add = () => {
    const classes                       = useStyles();
    const [herdName, setHerdName]       = useState( '' );
    const [displayName, setDisplayName] = useState( '' );
    const [driver, setDriver]           = useState( '' );
    const [freq, setFreq]               = useState( '' );
    const [house, setHouse]             = useState( '' );
    const [region, setRegion]           = useState( '' );
    const [creamery, setCreamery]       = useState( '' );
    const [consultants, setConsultants] = useState([] );
    const [goal, setGoal]               = useState( false );

    const resetAdd = () => {
        setHerdName( '' );
        setDisplayName( '' );
        setDriver( '' );
        setFreq( '' );
        setHouse( '' );
        setRegion( '' );
        setCreamery( '' );
        setConsultants([] );
        setGoal( false );
    };

    return (
        <>Add Herd</>

        // <form className = {classes.root} >
        //     <Grid container spacing = {3} >
        //         <Grid item xs = {6} >
        //             <HerdName setHerdName = {setHerdName} herdName = {herdName} />
        //         </Grid>
        //         <Grid item xs = {6} >
        //             <DisplayName setDisplayName = {setDisplayName} displayName = {displayName} />
        //         </Grid>
        //         <Grid item xs = {6} >
        //             <SoftwareDriver setDriver = {setDriver} driver = {driver} />
        //         </Grid>
        //         <Grid item xs = {6} >
        //             <Frequency setFreq = {setFreq} freq = {freq} />
        //         </Grid>
        //         <Grid item xs = {6} >
        //             <Housing setHouse = {setHouse} house = {house} />
        //         </Grid>
        //         <Grid item xs = {6} >
        //             <Regions setRegion = {setRegion} region = {region} />
        //         </Grid>
        //         <Grid item xs = {6} >
        //             <Creamery setCreamery = {setCreamery} creamery = {creamery} />
        //         </Grid>
        //         <Grid item xs = {6} >
        //             <Consultants setConsultants = {setConsultants} consultants = {consultants} />
        //         </Grid>
        //         <Grid item xs = {12} >
        //             <Goals setGoal = {setGoal} goal = {goal} />
        //         </Grid>
        //         <Grid item xs = {12} >
        //             <Action
        //                 herdName    = {herdName}
        //                 displayName = {displayName}
        //                 driver      = {driver}
        //                 freq        = {freq}
        //                 house       = {house}
        //                 region      = {region}
        //                 creamery    = {creamery}
        //                 consultants = {consultants}
        //                 goal        = {goal}
        //                 resetAdd    = {resetAdd}
        //             />
        //         </Grid>
        //     </Grid>
        // </form>
    );
}

export default Add;
