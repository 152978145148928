import React from 'react';
import './SummaryCss.css'

const S_Summary = p => {

    window
        .jQuery( '#s_summary' )
        .telerik_ReportViewer({
            reportServer : {
                url      : 'https://rmtest.standardnutrition.com:83/',
                username : 'rsmith',
                password : 'Mercedes@1'
            },
            reportSource : {
                // report : 'Samples/Dashboard',
                report : 'Summary/Summary'
                // parameters : { OrderNumber : $(this).val() }
            },
            documentMapVisible : false,
            scale              : 1.0,
            scaleMode          : 'FIT_PAGE_WIDTH',
            viewMode           : 'INTERACTIVE',
            printMode          : 'SPECIFIC',
            sendEmail          : { enabled : true }
        });

    return (
        <>
            {
                p.collapse ?
                    (
                        <div style = {{marginTop : '8em'}}
                             id    = 's_summary' >
                        </div>
                    ) :
                    (
                        <div style = {{display : 'none'}}
                             id    = 's_summary' >
                        </div>
                    )
            }
        </>
    )
}

export default S_Summary;
