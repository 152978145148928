import * as React from 'react'
import { Paper, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

const PaperItem = styled( Paper ) (({ theme }) => ({
    color: theme.palette.text.secondary
}))

const HeaderUnAssigned = () => {
    return (
        <PaperItem elevation = { 6 } sx={{ backgroundColor: 'primary.secondary' }} >
            <Typography style = {{ padding:'1em', textAlign:'center' }} >
                HERD(S) UNASSIGNED
            </Typography>
        </PaperItem>
    )
}

export default HeaderUnAssigned
