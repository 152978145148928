import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import axios from 'axios';
import conf from '../../../conf/conf';

const useStyles = makeStyles(theme => ({
    formControl : {
        width : '100%'
    },
    chips : {
        display  : 'flex',
        flexWrap : 'wrap'
    },
    chip : {
        margin : 2
    }
}));
const ITEM_HEIGHT      = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps : {
        style : {
            maxHeight : ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width     : 250
        }
    }
};
const Consultants = props => {

    const classes                             = useStyles();
    const [consultantList, setConsultantList] = useState([] );
    const handleChange = e => {
        props.setConsultants( e.target.value );
    };
    useEffect( () => {
        axios({
            method : 'get',
            url    : `${conf.getEnv().nodeServer}/herd/add/list/consultant`
        })
        .then( res => {
            if ( res.data.success )
                setConsultantList( res.data.data );
        });
    }, [] );
    return (
        <FormControl required className = {classes.formControl} >
            <InputLabel id = 'consultant-mutiple-chip-label' >Consultant</InputLabel>
            <Select
                multiple
                labelId     = 'consultant-mutiple-chip-label'
                id          = 'consultant-mutiple-chip'
                variant     = 'filled'
                value       = {props.consultants}
                onChange    = {handleChange}
                input       = {<Input id = 'consultant-select-multiple-chip' />}
                renderValue = {selected => (
                    <div className = {classes.chips} >
                        {selected.map( ( value, index ) => (
                            <Chip key = {index} label = {value} className = {classes.chip} />
                        ))}
                    </div>
                )}
                MenuProps = {MenuProps} >
                {consultantList.map( ( consultant, index) => (
                    <MenuItem key = {index} value = {consultant} >
                        {consultant}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default Consultants;
