import { useState } from 'react';
import { singletonHook } from 'react-singleton-hook';

const initBreeds      = [];
let   currentBreeds   = initBreeds;
let   globalSetBreeds = () => {};

export const useBreeds = singletonHook( initBreeds, () => {
    const [breeds, setBreeds] = useState( initBreeds );
    globalSetBreeds = setBreeds;
    currentBreeds   = breeds;
    return breeds;
});

export const setBreeds = b => globalSetBreeds( b );
export const getBreeds = () => currentBreeds;

// ################################################################################################

const initBreedsCheckedAll      = false;
let   currentBreedsCheckedAll   = initBreedsCheckedAll;
let   globalSetBreedsCheckedAll = () => {};

export const useBreedsCheckedAll = singletonHook( initBreedsCheckedAll, () => {
    const [breedsCheckedAll, setBreedsCheckedAll] = useState( initBreedsCheckedAll );
    globalSetBreedsCheckedAll = setBreedsCheckedAll;
    currentBreedsCheckedAll   = breedsCheckedAll;
    return breedsCheckedAll;
});

export const setBreedsCheckedAll = bca => globalSetBreedsCheckedAll( bca );
export const getBreedsCheckedAll = () => currentBreedsCheckedAll;
