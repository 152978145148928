import React from 'react';
import Divider from '@material-ui/core/Divider';
import Consultants from './breed/consultants/Consultants';
import Herds from './breed/herds/Herds';
import DateBegin from './breed/dates/DateBegin';
import DateEnd from './breed/dates/DateEnd';
import Types from './breed/type/Type';
import Regions from './breed/regions/Regions';
import Breeds from './breed/breeds/Breeds';
import Action from './breed/action/Action';
import House from './breed/house/House';
import Freq from './breed/freq/Freq';

const Breed = p => {

    return (
        <div style = {{display : 'flex', 'flexDirection' : 'column'}} >
            <div style = {{display : 'flex', 'flexDirection' : 'row' }} >
                <Types />
                <div style = {{display : 'flex', 'flexDirection' : 'column' }} >
                    <DateBegin />
                    <DateEnd />
                    <Regions />
                </div>
                <Consultants />
                <Herds />
                <div style = {{display : 'flex', 'flexDirection' : 'column' }} >
                    <Breeds />
                    <House />
                    <Freq />
                </div>
            </div>
            <Divider style = {{marginLeft : '1em', marginRight : '1em'}} />
            <div style = {{display : 'flex', 'flexDirection' : 'row'}} >
                <Action
                    handleCollapse  = {p.handleCollapse}
                    setShowViewer   = {p.setShowViewer}
                />
            </div>
        </div>
    );
}

export default Breed;
