import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import {Backdrop} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import axios from 'axios';
import conf from '../../../../../conf/conf';
import {useMessage, setMessage} from '../../../../../singletons/reports/message/singletonMessage';
import {useFreq, getFreq, setFreq} from '../../../../../singletons/reports/freq/singletonFreq';
import {useHouse, getHouse, setHouse} from '../../../../../singletons/reports/house/singletonHouse';
import {useHerds, getHerds, setHerds} from '../../../../../singletons/reports/herds/singletonHerds';
import {useBreeds, getBreeds, setBreeds} from '../../../../../singletons/reports/breeds/singletonBreeds';
import {useConsultants, getConsultants, setConsultants} from '../../../../../singletons/reports/consultants/singletonConsultants';
import {useRegions, getRegions, setRegions,
    useRegionsChecked, setRegionsChecked,
    useRegionsCheckedAll, setRegionsCheckedAll
} from '../../../../../singletons/reports/regions/singletonRegions';
import {useDateB, getDateB, setDateB,
    useDateE, getDateE, setDateE, getDateStringB, getDateStringE
} from '../../../../../singletons/reports/dates/singletonDates';
import {useType, getType, setType} from '../../../../../singletons/reports/type/singletonType';

const Action = p => {
    useMessage();
    useFreq();
    useHouse();
    useHerds();
    useBreeds();
    useConsultants();
    useRegions();
    useRegionsChecked();
    useRegionsCheckedAll();
    useDateB();
    useDateE();
    useType();

    const [loading, setLoading]             = useState(false );
    const [disableCsv, setDisableCsv]       = useState(true );
    const [disableSubmit, setDisableSubmit] = useState(true );

    const handleReset = () => {
        p.setShowViewer( false );
        setTimeout( () => {
            setType( 0 );
            setDateB( moment().startOf( 'month' ) );
            setDateE( moment().endOf( 'month' ) );
            setRegions( [] );
            setConsultants( [] );
            setHerds( [] );
            setRegionsChecked( [0] );
            setRegionsCheckedAll( false );
            setHouse( [] );
            setFreq( [] );
            setBreeds( [] );
        }, 280 );
    };
    const handleSubmit = () => {
        setLoading(true );
        axios({
            method : 'put',
            url    : `${conf.getEnv().nodeServer}/report/breed/submit`,
            data   : {
                reportType  : 'report',
                type        : getType(),
                beginDate   : getDateStringB(),
                endDate     : getDateStringE(),
                regions     : getRegions(),
                consultants : getConsultants(),
                herds       : getHerds(),
                house       : getHouse(),
                freq        : getFreq(),
                breeds      : getBreeds(),
            }
        })
        .then( res => {
            setLoading(false );
            if ( res.data.success ) {
                setMessage( 'Report Load Successful' );
                p.setShowViewer( true );
                p.handleCollapse();
            }
            else {
                setMessage( res.data.errMessage );
                p.setShowViewer( false );
            }
        });
    };
    const handleCsv = () => {
        setLoading(true );
        axios({
            method : 'put',
            url    : `${conf.getEnv().nodeServer}/report/breed/submit`,
            data   : {
                reportType  : 'csv',
                type        : getType(),
                beginDate   : getDateStringB(),
                endDate     : getDateStringE(),
                regions     : getRegions(),
                consultants : getConsultants(),
                herds       : getHerds(),
                house       : getHouse(),
                freq        : getFreq(),
                breeds      : getBreeds(),
            }
        })
        .then( res => {
            setLoading(false );
            if ( res.data.success )
                setMessage( 'Export CSV Successful' );
            else
                setMessage( res.data.errMessage );
        });
    };
    useEffect( () => {
        setDisableCsv( true );
        setDisableSubmit( true );
        if ( getRegions().length > 0 )
            if ( getConsultants().length > 0 )
                if ( getHerds().length > 0 )
                    if ( getBreeds().length > 0 )
                        if ( getHouse().length > 0 )
                            if ( getFreq().length > 0 ) {
                                setDisableCsv( false );
                                setDisableSubmit( false );
                            }
    }, [getRegions(), getConsultants(), getHerds(), getHouse(), getFreq(), getBreeds()] );
    return (
        <div style = {{marginTop : '1em', marginLeft : '1em'}} >
            <Button
                disableElevation
                variant = 'outlined'
                color   = 'primary'
                style   = {{minWidth : '11em'}}
                onClick = {handleReset} >
                Reset
            </Button>
            <Button
                disableElevation
                variant  = 'outlined'
                color    = 'primary'
                disabled = {disableCsv}
                style    = {{minWidth : '11em', marginLeft : '1em'}}
                onClick  = {handleCsv} >
                Export CSV
            </Button>
            <Button
                disableElevation
                variant  = 'outlined'
                color    = 'primary'
                disabled = {disableSubmit}
                style    = {{minWidth : '11em', marginLeft : '1em'}}
                onClick  = {handleSubmit} >
                Submit
            </Button>
            <Backdrop open = {loading} style = {{zIndex : '5', position : 'absolute'}} >
                <CircularProgress size = '8em' />
            </Backdrop>
        </div>
    );
}

export default Action;
