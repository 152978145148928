import React, { useEffect, useState } from 'react'
import { Divider, Typography, Paper, Button,  Stack } from '@mui/material'
import HeaderConsultant from './header/HeaderConsultant'
import ListConsultant from './list/ListConsultant'
import ListHerdAssigned from './list/ListHerdAssigned'
import ListHerdUnAssigned from './list/ListHerdUnAssigned'
import HeaderHerdAssigned from './header/HeaderHerdAssigned'
import HeaderHerdUnAssigned from './header/HeaderHerdUnAssigned'
import { createTheme, ThemeProvider, styled } from '@mui/material/styles'
import shadows from '@mui/material/styles/shadows'
import Header from './header/Header'
import Buttons from './buttons/Buttons'
import { sortBy, uniqBy } from 'lodash'
import axios from 'axios'
import conf from '../../../conf/conf'

const unCheck = ( checked, leftRight ) => {
    // console.log(checked) // Array of objects
    // console.log(leftRight) // Array of objects
    leftRight.forEach( lr => {
        checked.forEach( c => {
            if ( c === lr.uid ) {
                const idx = checked.indexOf( c )
                checked.splice( idx, 1 )
            }
        })
    })
    return checked
}

const not = ( a, b ) => {
    return a.filter( value => b.indexOf( value ) === -1 )
}

const intersection = ( a, b ) => {
    // console.log(a) // Array of strings
    // console.log(b) // Array of objects
    let data = []
    b.forEach( o => {
        a.forEach( s => {
            if ( o.uid === s )
                data.push( o )
        })
    })
    return data
}

const Update = () => {
    const [message, setMessage]                       = useState( '' )
    const [currentConsultant, setCurrentConsultant]   = useState( '' )
    const [selectedConsultant, setSelectedConsultant] = useState(0 )
    const [consultant, setConsultant]                 = useState([] )
    const [checked, setChecked]                       = useState([] )
    const [left, setLeft]                             = useState([] )
    const [right, setRight]                           = useState([] )
    const [data, setData]                             = useState([] )
    const [toBeUpdated, setToBeUpdated]               = useState([] )

    const leftChecked  = intersection( checked, left )
    const rightChecked = intersection( checked, right )

    const handleOnClickConsultant = ( idx, consultant ) => {
        setCurrentConsultant( consultant )
        setSelectedConsultant( idx )

        axios({
            method : 'get',
            url    : `${conf.getEnv().nodeServer}/tool/herdAssignment/list`
        })
            .then( res => {
                if ( res.data.success ) {
                    setData( res.data.data )
                    let   assigned = []
                    data.herd.forEach( a => {
                        if ( a.infoPacket.consultantId === consultant.consultantId )
                            assigned.push( a )
                    })
                    setLeft( assigned )

                    let unAssigned = []
                    let isFound    = false
                    // console.log(assigned)
                    data.herd.forEach( u => {
                        assigned.forEach( a => {
                            if ( u.infoPacket.herdId === a.infoPacket.herdId )
                                isFound = true
                        })
                        if ( !isFound )
                            unAssigned.push( u )
                        isFound = false
                    })
                    setRight( uniqBy( unAssigned, 'infoPacket.herdId' ) )
                    // console.log(unAssigned)
                }
            })
    }

    const handleToggle = uid => {
        const currentIndex = checked.indexOf( uid )
        const newChecked = [...checked]

        if ( currentIndex === -1 )
            newChecked.push( uid )
        else
            newChecked.splice( currentIndex, 1 )

        setChecked( newChecked )
    }

    const handleAllRight = () => {
        setRight( sortBy( right.concat( left ), 'infoPacket.herdName' ) )
        setLeft( [] )
    }

    const handleCheckedRight = () => {
        setRight( sortBy( right.concat( leftChecked ), 'infoPacket.herdName' ) )
        setLeft( not( left, leftChecked ) )
        setChecked( unCheck( checked, leftChecked ) )
        setToBeUpdated( not( left, leftChecked ) )
    }

    const handleCheckedLeft = () => {
        setLeft( sortBy( left.concat( rightChecked ), 'infoPacket.herdName' ) )
        setRight( not( right, rightChecked ) )
        setChecked( unCheck( checked, rightChecked ) )
        setToBeUpdated( sortBy( left.concat( rightChecked ), 'infoPacket.herdName' ) )
    }

    const handleAllLeft = () => {
        setLeft( sortBy( left.concat( right ), 'infoPacket.herdName' ) )
        setRight( [] )
    }

    const handleUpdate = () => {
        // console.log(toBeUpdated)
        axios({
            method : 'put',
            url    : `${conf.getEnv().nodeServer}/tool/herdAssignment/update`,
            data   : {
                assigned          : toBeUpdated,
                currentConsultant : currentConsultant
            }
        })
            .then( res => {
                if ( res.data.success )
                    setMessage( 'Update Successful' )
                else
                    setMessage( 'Update Failed' )
                setTimeout( () => {
                    setMessage( '' )
                }, 3300 )
            })
    }

    const Item = styled( Paper ) (({ theme }) => ({
        color: theme.palette.text.secondary
    }))

    const darkTheme = createTheme({
        palette: { mode: 'dark' },
        shadows: shadows.map( () => 'none' )
    })

    const initLists = data => {
        setData( data )
        setConsultant( data.consultant )
        setCurrentConsultant( data.consultant[0].consultantId )

        let assigned = []
        data.herd.forEach( a => {
            if ( data.consultant[0].consultantId === a.infoPacket.consultantId )
                assigned.push( a )
        })
        setLeft( assigned )

        let unAssigned = []
        let isFound    = false
        data.herd.forEach( u => {
            assigned.forEach( a => {
                if ( u.uid === a.uid )
                    isFound = true
            })
            if ( !isFound )
                unAssigned.push( u )
            isFound = false
        })
        setRight( unAssigned )
    }

    useEffect( () => {
        axios({
            method : 'get',
            url    : `${conf.getEnv().nodeServer}/tool/herdAssignment/list`
        })
            .then( res => {
                if ( res.data.success )
                    initLists( res.data.data )
            })
    }, [] )

    return (
        <ThemeProvider theme = { darkTheme } >
            <Item elevation = { 18 } sx = {{ backgroundColor: 'primary.secondary', padding:'1em' }} >
                <Header />
                <Stack direction = 'row' spacing = { 2 } >
                    <Stack spacing = { 1 } >
                        <HeaderConsultant />
                        <ListConsultant
                            consultant              = { consultant }
                            selectedConsultant      = { selectedConsultant }
                            handleOnClickConsultant = { handleOnClickConsultant }
                        />
                    </Stack>
                    <Stack spacing = { 1 } >
                        <HeaderHerdAssigned />
                        <ListHerdAssigned
                            handleToggle = { handleToggle }
                            left         = { left }
                            checked      = { checked }
                        />
                    </Stack>
                    <Stack spacing = { 2 } sx = {{ paddingTop: '13em' }} >
                        <Buttons
                            handleAllRight     = { handleAllRight }
                            right              = { right }
                            handleCheckedRight = { handleCheckedRight }
                            rightChecked       = { rightChecked }
                            handleCheckedLeft  = { handleCheckedLeft }
                            leftChecked        = { leftChecked }
                            handleAllLeft      = { handleAllLeft }
                            left               = { left }
                        />
                    </Stack>
                    <Stack spacing = { 1 } >
                        <HeaderHerdUnAssigned />
                        <ListHerdUnAssigned
                            handleToggle = { handleToggle }
                            right        = { right }
                            checked      = { checked }
                        />
                    </Stack>
                </Stack>
            </Item>
            <Divider style = {{ marginTop : '1em', marginBottom : '1em' }} />
            <Stack direction = 'row' spacing = { 2 } >
                <Button
                    disableElevation
                    variant  = 'outlined'
                    style    = {{ minWidth : '11em', marginRight : '1em' }}
                    onClick  = { handleUpdate } >
                    Update
                </Button>
                <Typography style = {{ marginTop : '0.5em' }} >
                    { message }
                </Typography>
            </Stack>
        </ThemeProvider>
    )
}

export default Update
