import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Badge from '@material-ui/core/Badge';
import List from './list/List';
import Add from './add/Add';
import Update from './update/Update';


const StyledBadgeList = withStyles(theme => ({
    badge : {
        right  : -140,
        left   : 100,
        top    : 25,
        border : '1px solid dimGray'
    }
}))( Badge );
const StyledBadgeUpdate = withStyles(theme => ({
    badge : {
        right  : -140,
        left   : 100,
        top    : 121,
        border : '1px solid dimGray'
    }
}))( Badge );
const TabPanel = props => {
    const { children, value, index } = props;

    return (
        <div
            hidden          = {value !== index}
            id              = {`vertical-tabpanel-${index}`}
            aria-labelledby = {`vertical-tab-${index}`} >
            {value === index && (
                <Box style = {{marginLeft : '2em', marginRight : '2em'}} >
                    {children}
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children : PropTypes.node,
    index    : PropTypes.any.isRequired,
    value    : PropTypes.any.isRequired
};
const useStyles = makeStyles(theme => ({
    root : {
        flexGrow    : 1,
        display     : 'flex',
        marginLeft  : '1em',
        marginRight : '1em'
    },
    tab : {
        minWidth : '12em'
    },
    tabs : {
        minWidth : '12em'
    }
}));
const Herds = () => {
    const classes                       = useStyles();
    const [value, setValue]             = useState(0 );
    const [badgeList, setBadgeList]     = useState(0 );
    const [badgeUpdate, setBadgeUpdate] = useState(0 );

    const handleChange = ( event, newValue ) => {
        setValue( newValue );
    };

    return (
        <div
            className = {classes.root} >
            <StyledBadgeList badgeContent   = {badgeList}   max = {999} />
            <StyledBadgeUpdate badgeContent = {badgeUpdate} max = {999} />
            <Tabs
                orientation = 'vertical'
                variant     = 'scrollable'
                value       = {value}
                onChange    = {handleChange}
                className   = {classes.tabs} >
                <Tab label = 'List'   value = {0} />
                <Tab label = 'Add'    value = {1} />
                <Tab label = 'Update' value = {2} />
                <Tab label = 'Remove' value = {3} />
            </Tabs>
            <TabPanel
                value = {value}
                index = {0} >
                <List setBadge = {setBadgeList} />
            </TabPanel>
            <TabPanel
                value = {value}
                index = {1} >
                <Add />
            </TabPanel>
            <TabPanel
                value = {value}
                index = {2} >
                <Update setBadge = {setBadgeUpdate} />
            </TabPanel>
            <TabPanel
                value = {value}
                index = {3} >
                Remove Herd
            </TabPanel>
        </div>
    );
}

export default Herds;
