import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import {Paper, TextField} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import axios from 'axios';
import conf from '../../../conf/conf';
import Divider from '@material-ui/core/Divider'

const useStyles = makeStyles(theme => ({
    bar : {
        marginBottom : '1em'
    },
    container : {
        maxHeight    : 460,
        paddingRight : '1em'
    }
}))
const Update = () => {
    const classes                                           = useStyles();
    const [disableUpdate, setDisableUpdate]                 = useState( true );
    const [disableCsv, setDisableCsv]                       = useState( true );
    const [messages, setMessages]                           = useState( [] );
    const [evalTotal, setEvalTotal]                         = useState( 'Working ...' );
    const [toast, setToast]                                 = useState( '' );
    const [environment, setEnvironment]                     = useState( '' );
    const [baseMilkValue, setBaseMilkValue]                 = useState( '' );
    const [fatValue, setFatValue]                           = useState( '' );
    const [proteinValue, setProteinValue]                   = useState( '' );
    const [solidNotFatValue, setSolidNotFatValue]           = useState( '' );
    const [somaticCellCountValue, setSomaticCellCountValue] = useState( '' );
    const [fatPercent, setFatPercent]                       = useState( '' );
    const [proteinPercent, setProteinPercent]               = useState( '' );
    const [solidNotFatPercent, setSolidNotFatPercent]       = useState( '' );

    const socketUrl = `${conf.getEnv().nodeSocketServer}`;
    const handleSockMessage = message => {
        if ( JSON.parse( message.data ).status === 'Inserted Current Revenue Metrics' ) {
            handleToast( 'Revenue Metric Update Completed' );
            setDisableCsv( false );
        }
        if ( JSON.parse( message.data ).status === 'Email CSV Successful' )
            handleToast( 'Export CSV Completed' );
        const msg = messages;
        msg.unshift( JSON.parse( message.data ) );
        setMessages( msg );
    };
    const {
        sendMessage,
        sendJsonMessage,
        lastMessage,
        lastJsonMessage,
        readyState,
        getWebSocket
    } = useWebSocket( socketUrl, {
        // onOpen          : () => console.log('opened'),
        onMessage       : message => handleSockMessage( message ),
        shouldReconnect : closeEvent => true
    });
    const handleToast = toast => {
        setToast( toast );
        setTimeout( () => {
            setToast( '' );
        }, 3500 );
    };
    const handleEnvironment = environment => {
        if ( environment.indexOf( '100.80' ) >= 0 )
            return 'Production';
        if ( environment.indexOf( '100.46' ) >= 0 )
            return 'Test';
        if ( environment.indexOf( '100.41' ) >= 0 )
            return 'Development';
    };
    const handleUpdate = () => {
        handleToast( 'Revenue Metric Update Started' );
        setMessages( [] );
        setDisableCsv(true );
        sendJsonMessage({
            path                  : '/tool/revenueMetric/update',
            baseMilkValue         : baseMilkValue,
            fatValue              : fatValue,
            proteinValue          : proteinValue,
            solidNotFatValue      : solidNotFatValue,
            somaticCellCountValue : somaticCellCountValue,
            fatPercent            : fatPercent,
            proteinPercent        : proteinPercent,
            solidNotFatPercent    : solidNotFatPercent
        });
    };
    const handleCsv = () => {
        handleToast( 'Export CSV Started' );
        setMessages( [] );
        sendJsonMessage({
            path : '/tool/revenueMetric/csv',
        });
    };
    const handleChange = e => {
        if ( e.target.name === 'baseMilkValue' )
            setBaseMilkValue( e.target.value );
        if ( e.target.name === 'fatValue' )
            setFatValue( e.target.value );
        if ( e.target.name === 'proteinValue' )
            setProteinValue( e.target.value );
        if ( e.target.name === 'solidNotFatValue' )
            setSolidNotFatValue( e.target.value );
        if ( e.target.name === 'somaticCellCountValue' )
            setSomaticCellCountValue( e.target.value );
        if ( e.target.name === 'fatPercent' )
            setFatPercent( e.target.value );
        if ( e.target.name === 'proteinPercent' )
            setProteinPercent( e.target.value );
        if ( e.target.name === 'solidNotFatPercent' )
            setSolidNotFatPercent( e.target.value );
    };
    useEffect( () => {
        setDisableUpdate(true );
        if ( baseMilkValue === '' )
            return;
        if ( fatValue === '' )
            return;
        if ( proteinValue === '' )
            return;
        if ( solidNotFatValue === '' )
            return;
        if ( somaticCellCountValue === '' )
            return;
        if ( fatPercent === '' )
            return;
        if ( proteinPercent === '' )
            return;
        if ( solidNotFatPercent === '' )
            return;
        setDisableUpdate(false );
    }, [baseMilkValue, fatValue, proteinValue, solidNotFatValue, somaticCellCountValue, fatPercent, proteinPercent, solidNotFatPercent] )
    useEffect( () => {
        axios({
            method : 'get',
            url    : `${conf.getEnv().nodeServer}/tool/revenueMetric/list`
        })
        .then( res => {
            // console.log(res.data)
            if ( res.data.success ) {
                setBaseMilkValue( res.data.data.baseMilkValue );
                setFatValue( res.data.data.fatValue );
                setProteinValue( res.data.data.proteinValue );
                setSolidNotFatValue( res.data.data.solidNotFatValue );
                setSomaticCellCountValue( res.data.data.somaticCellCountValue );
                setFatPercent( res.data.data.fatPercent );
                setProteinPercent( res.data.data.proteinPercent );
                setSolidNotFatPercent(res.data.data.solidNotFatPercent );
                setEvalTotal( res.data.data.evalTotal);
                setEnvironment( handleEnvironment( res.data.data.environment ) );
            }
        });
    }, [] )
    return (
        <>
            <Paper>
                <Grid container >
                    <Grid container item xs = {12} >
                        <Grid item xs = {12} >
                            <AppBar
                                elevation = {0}
                                position  = 'static'
                                className = {classes.bar} >
                                <Toolbar
                                    variant = 'dense' >
                                    <Typography
                                        variant = 'h6' >
                                        Revenue Metric Update&emsp;&emsp;&emsp;{environment}&emsp;&emsp;&emsp;{toast}
                                    </Typography>
                                </Toolbar>
                            </AppBar>
                        </Grid>
                    </Grid>
                    <Grid container item xs = {12} wrap = 'nowrap' >
                        <Grid item xs = {2} style = {{marginLeft:'1em', minWidth:'16em'}} >
                            <TextField label = 'Base Milk Value' value = {baseMilkValue} name = 'baseMilkValue' onChange = {handleChange} style = {{marginBottom:'1em'}} />
                            <TextField label = 'Fat Value' value = {fatValue} name = 'fatValue' onChange = {handleChange} style = {{marginBottom:'1em'}} />
                            <TextField label = 'Protein Value' value = {proteinValue} name = 'proteinValue' onChange = {handleChange} style = {{marginBottom:'1em'}} />
                            <TextField label = 'Solid Not Fat Value' value = {solidNotFatValue} name = 'solidNotFatValue' onChange = {handleChange} style = {{marginBottom:'1em'}} />
                            <TextField label = 'Somatic Cell Count Value' value = {somaticCellCountValue} name = 'somaticCellCountValue' onChange = {handleChange} style = {{marginBottom:'1em'}} />
                            <TextField label = 'Fat Percent' value = {fatPercent} name = 'fatPercent' onChange = {handleChange} style = {{marginBottom:'1em'}} />
                            <TextField label = 'Protein Percent' value = {proteinPercent} name = 'proteinPercent' onChange = {handleChange} style = {{marginBottom:'1em'}} />
                            <TextField label = 'Solid Not Fat Percent' value = {solidNotFatPercent} name = 'solidNotFatPercent' onChange = {handleChange} style = {{marginBottom:'1em'}} />
                        </Grid>
                        <Grid item xs = {10} >
                            <TableContainer
                                className = {classes.container} >
                                <Table
                                    stickyHeader
                                    size = 'small' >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style = {{minWidth : 166}} >Status</TableCell>
                                            <TableCell>Evaluation Count</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        { messages.map( ( msg, idx ) => (
                                            <TableRow key = {idx} >
                                                <TableCell>{msg.status}</TableCell>
                                                <TableCell>{msg.count}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
            <Divider style = {{marginTop : '1em'}} />
            <Grid container item xs = {12} wrap = 'nowrap' style = {{marginTop:'1em'}} >
                <Grid container direction = 'row' wrap = 'nowrap' >
                    <Grid item >
                        <Button
                            disableElevation
                            variant  = 'outlined'
                            disabled = {disableUpdate}
                            style    = {{minWidth : '11em', marginRight : '1em'}}
                            onClick  = {handleUpdate} >
                            Submit
                        </Button>
                    </Grid>
                    <Grid item >
                        <Button
                            disableElevation
                            variant  = 'outlined'
                            disabled = {disableCsv}
                            style    = {{minWidth : '11em', marginRight : '1em'}}
                            onClick  = {handleCsv} >
                            Export CSV
                        </Button>
                    </Grid>
                    <Grid item >
                        <Typography style = {{marginTop : '0.5em'}} >
                            Evaluation Count {evalTotal}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default Update;
