import React, {useState} from 'react';
import MomentUtils from '@date-io/moment';
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {makeStyles} from '@material-ui/core/styles';
import {Paper} from '@material-ui/core';
import {useDateB, getDateB, setDateB} from '../../../../../singletons/reports/dates/singletonDates';

const useStyles = makeStyles(theme => ({
    root : {
        marginLeft : theme.spacing( 2 )
    },
    paper : {
        padding : '10px'
    }
}));

const DateBegin = () => {
    useDateB();
    const classes                   = useStyles();
    const [beginDate, setBeginDate] = useState( getDateB() );

    const handleChange = e => {
        const date = e.toDate();
        setBeginDate(date);
        setDateB(date);
    };

    return (
        <div
            className = {classes.root} >
            <Paper
                className = {classes.paper} >
                <MuiPickersUtilsProvider
                    utils = {MomentUtils} >
                    <DatePicker
                        value    = {beginDate}
                        onChange = {handleChange}
                        autoOk   = {true}
                        label    = 'Start Date'
                        format   = 'MMMM D, YYYY'
                        variant  = 'inline'
                    />
                </MuiPickersUtilsProvider>
            </Paper>
        </div>
    );
}

export default DateBegin;
