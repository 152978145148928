import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles(theme => ({
    formControl : {
        width : '100%'
    }
}));

const Regions = props => {
    const classes      = useStyles();
    const handleChange = e => {
        props.setRegion( e.target.value );
    };

    return (
        <FormControl required size = 'small' className = {classes.formControl} >
            <InputLabel id = 'region-select-label' >Region</InputLabel>
            <Select
                labelId  = 'region-select-label'
                id       = 'region-select'
                value    = {props.region}
                onChange = {handleChange} >
                <MenuItem value = {'Midwest'} >Midwest</MenuItem>
                <MenuItem value = {'Northeast'} >Northeast</MenuItem>
                <MenuItem value = {'Northwest'} >Northwest</MenuItem>
                <MenuItem value = {'High Plains'} >High Plains</MenuItem>
            </Select>
        </FormControl>
    );
}

export default Regions;
