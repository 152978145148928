import React from 'react';
import Divider from '@material-ui/core/Divider';
import Action from './summary/action/Action';
import Types from './summary/type/Type';
import DateBegin from './summary/dates/DateBegin';
import DateEnd from './summary/dates/DateEnd';
import Regions from './summary/regions/Regions';
import Consultants from './summary/consultants/Consultants';
import Herds from './summary/herds/Herds';
import House from './summary/house/House';
import Freq from './summary/freq/Freq';

const Summary = p => {

    return (
        <div style = {{display : 'flex', 'flexDirection' : 'column'}} >
            <div style = {{display : 'flex', 'flexDirection' : 'row' }} >
                <Types />
                <div style = {{display : 'flex', 'flexDirection' : 'column' }} >
                    <DateBegin />
                    <DateEnd />
                    <Regions />
                </div>
                <Consultants />
                <Herds />
                <div style = {{display : 'flex', 'flexDirection' : 'column' }} >
                    <House />
                    <Freq />
                </div>
            </div>
            <Divider style = {{marginLeft : '1em', marginRight : '1em'}} />
            <div style = {{display : 'flex', 'flexDirection' : 'row'}} >
                <Action
                    handleCollapse  = {p.handleCollapse}
                    setShowViewer   = {p.setShowViewer}
                />
            </div>
        </div>
    );
}

export default Summary;
