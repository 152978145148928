import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemText from '@material-ui/core/ListItemText';
import {Paper} from '@material-ui/core';
import {useType, getType, setType} from '../../../../../singletons/reports/type/singletonType';

const Types = () => {
    useType();

    const handleListItemClick = ( e, index ) => {
        setType( index );
    };

    return (
        <Paper style = {{marginLeft : '1em', marginBottom : '1em'}} >
            <List
                disablePadding
                component = 'div'
                subheader = {
                    <ListSubheader
                        component = 'div' >
                        Reports
                    </ListSubheader>
                } >
                <ListItem
                    button
                    selected  = {getType() === 0}
                    onClick   = {e => handleListItemClick( e, 0 )} >
                    <ListItemText
                        primary = 'Summary'
                    />
                </ListItem>
                <ListItem
                    button
                    selected  = {getType() === 1}
                    onClick   = {e => handleListItemClick( e, 1 )} >
                    <ListItemText
                        primary = 'Summary Milk'
                    />
                </ListItem>
                <ListItem
                    button
                    selected  = {getType() === 2}
                    onClick   = {e => handleListItemClick( e, 2 )} >
                    <ListItemText
                        primary = 'Performance Milk'
                    />
                </ListItem>
                <ListItem
                    button
                    selected  = {getType() === 3}
                    onClick   = {e => handleListItemClick( e, 3 )} >
                    <ListItemText
                        primary = 'Reproduction Report'
                    />
                </ListItem>
            </List>
        </Paper>
    );
}

export default Types;
