import { useState } from 'react';
import { singletonHook } from 'react-singleton-hook';

const initHerds      = [];
let   currentHerds   = initHerds;
let   globalSetHerds = () => {};

export const useHerds = singletonHook( initHerds, () => {
    const [herds, setHerds] = useState( initHerds );
    globalSetHerds = setHerds;
    currentHerds   = herds;
    return herds;
});

export const setHerds = h => globalSetHerds( h );
export const getHerds = () => currentHerds;
