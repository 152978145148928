import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Checkbox, ListItem, ListItemText, Paper} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import {useRegions, getRegions, setRegions,
    useRegionsChecked, getRegionsChecked, setRegionsChecked,
    useRegionsCheckedAll, getRegionsCheckedAll, setRegionsCheckedAll
} from '../../../../../singletons/reports/regions/singletonRegions';

const useStyles = makeStyles(theme => ({
    root : {
        minWidth : 158
    },
    paper : {
        marginTop    : theme.spacing( 2 ),
        marginBottom : theme.spacing( 2 ),
        marginLeft   : '1em'
    },
    midWest : {
        maxHeight : '2em'
    },
    northEast : {
        maxHeight : '2em'
    },
    northWest : {
        maxHeight : '2em'
    },
    highPlains : {
        maxHeight     : '2em',
        paddingTop    : '9px',
        paddingBottom : '12px'
    },
    txt : {
        '& .MuiTypography-body2' : {
            fontSize : '0.75rem'
        },
        marginBottom : theme.spacing( 0.5 )
    }
}));
const Regions = () => {
    useRegions();
    useRegionsChecked();
    useRegionsCheckedAll();
    const classes      = useStyles();
    const handleToggle = ( value, region ) => () => {
        const currentIndex = getRegionsChecked().indexOf( value );
        let newChecked     = [...getRegionsChecked()];
        let newRegion      = [...getRegions()];
        if ( currentIndex === -1 ) {
            newChecked.push( value );
            newRegion.push( region );
            setRegions( newRegion );
        }
        else {
            newChecked.splice( currentIndex, 1 );
            newRegion.splice( currentIndex - 1, 1 );
            setRegions( newRegion );
        }
        setRegionsChecked( newChecked );
        /////////////////////////////
        ( newChecked.length === 4 ) ? setRegionsCheckedAll( true ) : setRegionsCheckedAll( false );
    };
    const handleChange = () => {
        setRegionsCheckedAll( ! getRegionsCheckedAll() );
        let newChecked = [];
        let newRegion  = [];
        if ( getRegionsCheckedAll() ) {
            setRegionsChecked( newChecked );
            setRegions( newRegion );
        }
        else {
            newChecked.push( 1 );
            newRegion.push( 'Midwest' );
            newChecked.push( 2 );
            newRegion.push( 'Northeast' );
            newChecked.push( 3 );
            newRegion.push( 'Northwest' );
            newChecked.push( 4 );
            newRegion.push( 'High Plains' );
            setRegionsChecked( newChecked );
            setRegions( newRegion );
        }
    };
    return (
        <div
            className = {classes.root} >
            <Paper className = {classes.paper} >
                <ListItem key = {0} dense button onClick = {handleChange} >
                    <Checkbox
                        edge    = 'start'
                        checked = {getRegionsCheckedAll()}
                    />
                    <ListItemText primary = 'Regions' className = {classes.txt} />
                </ListItem>
                <Divider />
                <ListItem key = {1} dense button onClick = {handleToggle(1, 'Midwest' )} className = {classes.midWest} >
                    <Checkbox
                        edge    = 'start'
                        checked = {getRegionsChecked().indexOf( 1 ) !== -1}
                    />
                    <ListItemText primary = 'Midwest' />
                </ListItem>
                <ListItem key = {2} dense button onClick = {handleToggle(2, 'Northeast' )} className = {classes.northEast} >
                    <Checkbox
                        edge    = 'start'
                        checked = {getRegionsChecked().indexOf( 2 ) !== -1}
                    />
                    <ListItemText primary = 'Northeast' />
                </ListItem>
                <ListItem key = {3} dense button onClick = {handleToggle(3, 'Northwest' )} className = {classes.northWest} >
                    <Checkbox
                        edge    = 'start'
                        checked = {getRegionsChecked().indexOf( 3 ) !== -1}
                    />
                    <ListItemText primary = 'Northwest' />
                </ListItem>
                <ListItem key = {4} dense button onClick = {handleToggle(4, 'High Plains' )} className = {classes.highPlains} >
                    <Checkbox
                        edge    = 'start'
                        checked = {getRegionsChecked().indexOf( 4 ) !== -1}
                    />
                    <ListItemText primary = 'High Plains' />
                </ListItem>
            </Paper>
        </div>
    );
}

export default Regions;
