import { useState } from 'react';
import { singletonHook } from 'react-singleton-hook';

const initType      = 0;
let   currentType   = initType;
let   globalSetType = () => {};

export const useType = singletonHook( initType, () => {
    const [type, setType] = useState( initType );
    globalSetType = setType;
    currentType   = type;
    return type;
});

export const setType = t => globalSetType( t );
export const getType = () => currentType;
