import { useState } from 'react';
import { singletonHook } from 'react-singleton-hook';

const initFreq      = '';
let   currentFreq   = initFreq;
let   globalSetFreq = () => {};

export const useFreq = singletonHook( initFreq, () => {
    const [freq, setFreq] = useState( initFreq );
    globalSetFreq = setFreq;
    currentFreq   = freq;
    return freq;
});

export const setFreq = f => globalSetFreq( f );
export const getFreq = () => currentFreq;
