import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import logo from '../logo/logo.png';


const useStyles = makeStyles((theme) => ({
    root : {
        flexGrow : 1
    },
    title : {
        flexGrow    : 1,
        paddingLeft : '1em',
        color       : '#FFF'
    },
    bar : {
        background : '#000'
    }
}));
export const TopBar = ({setLogout}) => {


    const classes = useStyles();
    const handleLogout = () => {
        setLogout(true);
    }
    return (
        <div
            className = {classes.root} >
            <CssBaseline />
            <AppBar
                elevation = {0}
                position  = 'static'
                className = {classes.bar} >
                <Toolbar>
                    <img
                        src = {logo}
                        alt = 'logo'
                    />
                    <Typography
                        variant   = 'h6'
                        className = {classes.title} >
                        RM ULTIMATE
                    </Typography>
                    <Button variant = 'outlined' style={{right: '0px'}} onClick={handleLogout} >Log Out</Button>
                </Toolbar>
                
            </AppBar>
        </div>
    );
}


