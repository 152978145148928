import { useState } from 'react';
import { singletonHook } from 'react-singleton-hook';

const initConsultants      = [];
let   currentConsultants   = initConsultants;
let   globalSetConsultants = () => {};

export const useConsultants = singletonHook( initConsultants, () => {
    const [consultants, setConsultants] = useState( initConsultants );
    globalSetConsultants = setConsultants;
    currentConsultants   = consultants;
    return consultants;
});

export const setConsultants = c => globalSetConsultants( c );
export const getConsultants = () => currentConsultants;
