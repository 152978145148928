import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import axios from 'axios';
import conf from '../../../conf/conf';

const useStyles = makeStyles(theme => ({
    root : {
        width : '100%'
    },
    container : {
        maxHeight : 730
    }
}));

const List = p => {
    const classes         = useStyles();
    const [rows, setRows] = useState( [] );

    useEffect( () => {
        axios({
            method : 'get',
            url    : `${conf.getEnv().nodeServer}/herd/list`
        })
        .then( res => {
            if ( res.data.success ) {
                // console.log(res.data.data);
                let rows = [];
                res.data.data.forEach( row => {
                    let consultants = '';
                    for ( let i = 0; i < row.ar_consultants.length; i++ ) {
                        if ( i < row.ar_consultants.length - 1 )
                            consultants += row.ar_consultants[i].st_name + ', ';
                        else
                            consultants += row.ar_consultants[i].st_name;
                    }
                    rows.push({
                        herdId      : row.st_herdId,
                        herdName    : row.st_herdName,
                        displayName : row.st_displayName,
                        driver      : row.st_driver,
                        freq        : row.st_freq,
                        house       : row.st_house,
                        region      : row.st_region,
                        creamery    : row.ob_creamery.st_name,
                        consultants : consultants,
                        goal        : row.goal ? 'Yes' : 'No'
                    })
                })
                setRows( rows );
                p.setBadge( rows.length );
            }
        });
    }, [] );

    return (
        <Paper
            className = {classes.root} >
            <TableContainer
                className = {classes.container} >
                <Table
                    stickyHeader
                    size = 'small' >
                    <TableHead>
                        <TableRow>
                            <TableCell style = {{minWidth : 170, backgroundColor : '#606060'}} >NAME</TableCell>
                            <TableCell style = {{minWidth : 170, backgroundColor : '#606060'}} >DISPLAY NAME</TableCell>
                            <TableCell style = {{minWidth : 100, backgroundColor : '#606060'}} >SOFTWARE DRIVER</TableCell>
                            <TableCell style = {{minWidth : 100, backgroundColor : '#606060'}} >FREQUENCY</TableCell>
                            <TableCell style = {{minWidth : 70,  backgroundColor : '#606060'}} >HOUSING</TableCell>
                            <TableCell style = {{minWidth : 70,  backgroundColor : '#606060'}} >REGION</TableCell>
                            <TableCell style = {{minWidth : 100, backgroundColor : '#606060'}} >CREAMERY</TableCell>
                            <TableCell style = {{minWidth : 100, backgroundColor : '#606060'}} >CONSULTANT</TableCell>
                            <TableCell style = {{minWidth : 70,  backgroundColor : '#606060'}} >GOALS</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map( row => (
                            <TableRow
                                key = {row.herdId} >
                                <TableCell>{row.herdName}</TableCell>
                                <TableCell>{row.displayName}</TableCell>
                                <TableCell>{row.driver}</TableCell>
                                <TableCell>{row.freq}</TableCell>
                                <TableCell>{row.house}</TableCell>
                                <TableCell>{row.region}</TableCell>
                                <TableCell>{row.creamery}</TableCell>
                                <TableCell>{row.consultants}</TableCell>
                                <TableCell>{row.goal}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}

export default List;
