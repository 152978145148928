import { useState } from 'react';
import { singletonHook } from 'react-singleton-hook';
import moment from 'moment';

const initDateB      = moment().startOf( 'month' ).toDate();
let   currentDateB   = initDateB;
let   globalSetDateB = () => {};

export const useDateB = singletonHook( initDateB, () => {
    const [dateB, setDateB] = useState( initDateB );
    globalSetDateB = setDateB;
    currentDateB   = dateB;
    return dateB;
});

export const setDateB = db => globalSetDateB( db );
export const getDateB = () => currentDateB;
export const getDateStringB = () => moment(currentDateB).format( 'MM/DD/YYYY' );

// ################################################################################################

const initDateE      = moment().endOf( 'month' ).toDate();
let   currentDateE   = initDateE;
let   globalSetDateE = () => {};

export const useDateE = singletonHook( initDateE, () => {
    const [dateE, setDateE] = useState( initDateE );
    globalSetDateE = setDateE;
    currentDateE   = dateE;
    return dateE;
});

export const setDateE = de => globalSetDateE( de );
export const getDateE = () => currentDateE;
export const getDateStringE = () => moment(currentDateE).format( 'MM/DD/YYYY' );
