import * as React from 'react'
import { Box, List, ListItemButton, ListItemText } from '@mui/material'

const ListConsultant = p  => {

    return (
        <Box sx = {{ width: 400, height: 440, overflow: 'auto' }} >
            <List>
                { p.consultant.map( ( row, index ) => {
                    return (
                        <ListItemButton
                            key      = {index}
                            selected = {p.selectedConsultant === index}
                            onClick  = {() => p.handleOnClickConsultant( index, row )} >
                            <ListItemText
                                primary = {row.consultantName}
                            />
                        </ListItemButton>
                    )
                })}
            </List>
        </Box>
    )
}

export default ListConsultant
